<template>
    <button
        v-bind:class="['g-button-link', size]"
    >
        <slot/>
    </button>
</template>


<script>
export default {
    name: 'GLinkButton',
    props: {
        size: {
            default: 'medium'
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    border: none;
}

.g-button-link {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: var(--secondary-hover, $secondary-hover);
    background-color: transparent;
    font-weight: 500;
    padding: 0;
    height: auto;
    width: auto;
    text-decoration: underline;

    &:deep(svg) {
        fill: var(--secondary-hover, $secondary-hover);
    }

    &:hover {
        color: var(--primary, $primary);

        &:deep(svg) {
            fill: var(--primary, $primary);
        }
    }

    &.small {
        font-size: var(--button-font-size-small, $button-font-size-small);
        gap: 3px;
    }

    &:disabled {
        cursor: default;
        color: var(--disabled, $disabled);

        &:deep(svg) {
            fill: var(--disabled, $disabled);
        }

        &:hover {
            color: var(--disabled, $disabled);

            &:deep(svg) {
                fill: var(--disabled, $disabled);
            }
        }
    }
}
</style>