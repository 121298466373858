<template>
    <div>
        <div class="text">{{ $t('profile.popupFaqClient') }}</div>
        <div class="footer">
            <g-button icon-color="danger" @click="closePopup">
                <g-symbol name="close" width="24" height="24"/>
                {{ $t('button.cancel') }}
            </g-button>
            <g-button fill="danger" @click="deleteServiceClient">
                <g-symbol name="delete" width="24" height="24"/>
                {{ $t('button.delete') }}
            </g-button>
        </div>
    </div>
</template>

<script>

import { useProfileResource } from '@profile';


export default {
    name: 'RemoveProfile',

    emits: ['performed'],
    props: {
        client: {
            type: Object
        }
    },

    setup(props, {emit}) {
        const clientResource = useProfileResource('Client');

        const deleteServiceClient = () => {
            let clientIndex = clientResource.state.indexOf(props.client);
            clientResource.delete(props.client.id).then(()=>{
                clientResource.collection.value.splice(clientIndex, 1);
            });

            emit('performed');
        };

        function closePopup() {
            emit('performed');
        }

        return {
            deleteServiceClient,
            closePopup
        };
    }
};
</script>

<style scoped lang="scss">
.g-caption {
    border-bottom: 1px solid var(--separator-color, $separator-color);
    margin-bottom: 35px;
    padding-bottom: 14px;
}

.text {
    font-size: var(--text-lg, $text-lg);
    line-height: 150%;
}

.footer {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-top: 32px;

    &:deep(.g-button) {
        width: 100%;
    }
}

@media screen and (max-width: 460px) {
    .g-popup .footer {
        flex-direction: column;
        gap: 12px;
    }
}
</style>
