<template>
    <div class="g-phone-input">
        <g-dropdown :options="options"
                    :option-text="optionText"
                    :option-value="optionValue"
                    v-model="selected"
                    :label="'code'"
                    return-item
                    :size="size">
        </g-dropdown>
        <span ref="input" class="g-input-wrapper">
            <g-input ref="input"
                     @keydown="inputEvent"
                     @input.prevent="inputEvent"
                     :label="'label'"
                     :size="size"
                     v-model:model-value="inputValue"
                     :disabled="!selected"/>
        </span>
    </div>
</template>

<script>
import { ref, watch, nextTick } from 'vue';

import phoneMask from '@/Ship/actions/phoneMask';

export default {
    name: 'GPhoneInput',
    inheritAttrs: false,
    emits: ['update:phoneNumber'],
    props: {
        size: {
            type: String,
            default: 'large',
        },
        options: { type: Array, default: () => [] },
        optionText: {
            Type: String,
        },
        optionValue: {
            Type: String,
        },
    },
    setup(props, { emit }) {
        const selected = ref(null);
        const inputValue = ref('');
        const input = ref(null);
        const inputEvent = event => { inputValue.value = phoneMask(event, selected.value); };

        watch(selected, () => {
            nextTick(() => {
                input.value.children[0].children[0].children[1].focus();
            });

            inputValue.value = '';

        });

        watch(inputValue, () => {
            emit('update:phoneNumber', inputValue.value);
        });

        return {
            inputEvent,
            phoneMask,
            inputValue,
            selected,
            input,
        };
    }
};
</script>

<style lang="scss" scoped>
.g-phone-input {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);

    :deep(.g-dropdown) {
        width: 30%;
        margin: 0;
    }

    :deep(.g-dropdown > div) {
        border: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid var(--input-border, $input-border);

        input {
            padding-right: 5px;
        }
    }

    .g-input-wrapper {
        flex: 1;

        :deep(.g-input) {
            margin: initial;
        }

        :deep(.g-input > .input-row) {
            border: none;
            border-radius: 0 var(--input-rounding, $input-rounding) var(--input-rounding, $input-rounding) 0;
        }
    }

    &.error {
        border-color: var(--danger, $danger);
        box-shadow: 0 0 0 1px var(--danger, $danger);

        &:deep(.g-dropdown > div) {
            border-right-color: var(--danger, $danger);
        }

        &:deep(.g-dropdown) > div > label {
            color: var(--danger, $danger);
        }

        div > label {
            color: var(--danger, $danger);
        }

        .hint-text {
            color: var(--danger, $danger);
        }
    }

    &:focus-within {
        border-color: var(--secondary, $secondary);
        box-shadow: 0 0 0 1px var(--secondary, $secondary);

        &:deep(.g-dropdown) > div > label {
            color: var(--secondary, $secondary);
        }

        &:deep(.g-dropdown > div) {
            border-right-color: var(--secondary, $secondary);
        }
    }
}
</style>
