<template>
    <div class="wrapper">
        <aside class="env-sidebar">
            <env-navbar/>
        </aside>
        <env-topbar>
            <!--<g-caption weight="600" size="4" class="toolbar part-toolbar">{{ $t(`page.${title}`) }}</g-caption>-->
            <form>
                <g-input label="Address" class="address"/>
                <g-input label="Amount" class="amount"/>
                <g-button class="send-btn" fill="primary" size="large">Send</g-button>
            </form>
        </env-topbar>
        <main>
            <environment/>
        </main>
    </div>
</template>

<script>
import { useEnvComponent } from '@environment';

const EnvTopbar = useEnvComponent('EnvTopbar');
const EnvNavbar = useEnvComponent('EnvNavbar');
//const EnvSidebar = useEnvComponent('EnvSidebar');

export default {
    name: 'Layout',
    components: {
        EnvTopbar,
        EnvNavbar,
    }
};
</script>

<style lang="scss">
.wrapper {
    height: 100vh;
    //overflow: auto;
    overflow: hidden;
    min-width: var(--min-width, $min-width);
    display: grid;
    grid-gap: var(--env-gap, $env-gap);
    grid-template-rows: var(--topbar-height, $topbar-height) auto;
    grid-template-columns: $leftbar-width auto;
}

aside {
    z-index: 3;
    grid-row: 1/3;
    display: flex;
    align-items: stretch;

    & > div {
        flex-grow: 1;
        overflow: hidden;
        position: relative;
    }
}

main {
    width: calc(100vw - #{$navbar-width});
    height: calc(100vh - #{$topbar-height});
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: var(--env-bg, $env-bg);

    .leftbar {
        flex-shrink: 0;
        overflow: hidden;
        width: $leftbar-width;
        transition: width 0.3s;
        border-right: 1px solid var(--separator-color, $separator-color);

        &:not(.show) {
            width: 0 !important;
        }
    }

    .environment {
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        background-color: var(--env-bg, $env-bg);
    }

    .rightbar {
        flex-shrink: 0;
        //width: $rightbar-width;
        border-left: 1px solid var(--separator-color, $separator-color);
        background: var(--env-bg, $env-bg);
    }
}

.sidebar-enter-active,
.sidebar-leave-active {
    position: absolute;
    transition: all 0.3s ease-out;
}

.sidebar-enter-to {
    right: 0;
}

.sidebar-enter-from {
    right: -100%;
}

.sidebar-leave-to {
    left: -100%;
}

.sidebar-leave-from {
    left: 0;
}

@media screen and (max-width: 1300px) and (min-width: 1101px) {
    .wrapper {
        grid-template-columns: 190px auto;
    }

    main {
        width: calc(100vw - 190px);
    }
}

@media screen and (max-width: 1100px) {
    .wrapper {
        display: block;
    }

    main {
        width: 100%;
        height: calc(100% - 88px);
    }
}

@media screen and (max-width: 440px) {
    main .rightbar {
        border-left: none;
    }
}

form {
    width: 100%;
    max-width: 364px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    gap: 8px;

    .g-input {
        flex-grow: 1;

        &.address {
            width: 100%;
            flex-grow: 1;
        }
    }
}
</style>
