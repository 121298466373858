<template>
    <div class="g-dropdown" :class="[size, {active:  !!modelValue}, { 'disabled':  disabled}]">
        <div :class="{ 'show-datalist': show }">
            <label>{{ label }}</label>
            <input v-bind="$attrs"
                   v-on:focus="showList"
                   v-on:blur="hideList"
                   ref="dropdownInput"
                   readonly>
            <span class="input-btn" @click="toggleList">
                <g-symbol name="arrow-down" width="24" height="24"/>
            </span>
            <div ref="datalist"
                 class="datalist"
                 v-bind:class="{ 'to-top': toTop }">
                <label v-for="(option, key) in options"
                       v-bind:key="key"
                       v-bind:class="{active: key === modelValue}">
                    <input type="radio"
                           v-model="selectedIndex"
                           v-bind:value="key"
                    >
                    {{ option[optionText] }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, watch, } from 'vue';

export default {
    name: 'GDropdown',
    inheritAttrs: false,
    props: {
        modelValue: {
            type: Object,
            default: () => {}
        },
        options: {
            type: Object
        },
        optionText: {
            Type: [String, Object],
        },
        optionValue: {
            Type: [String, Object],
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large',
        },
        label: { type: String },
        returnItem: { type: Boolean, default: false }
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {

        const zIndex = ref(1);
        const show = ref(false);
        const toTop = ref(false);
        const datalist = ref(null);
        const selectedIndex = ref(null);
        const dropdownInput = ref(null);

        const showList = () => {
            const { y } = datalist.value.getBoundingClientRect();
            toTop.value = (y + 300) > screen.availHeight;
            show.value = true;
            zIndex.value++;
        };
        const hideList = () => {
            setTimeout(() => {
                show.value = false;
            }, 200);
            setTimeout(() => {
                zIndex.value--;
            }, 400);
        };
        const toggleList = () => {
            if (!show.value) dropdownInput.value.focus();
        };

        watch(selectedIndex, () => {
            emit('update:modelValue', props.options[selectedIndex.value]
                && props.options[selectedIndex.value][props.optionValue] || '');

            dropdownInput.value.value = props.options[selectedIndex.value]
                && props.options[selectedIndex.value][props.optionText] || '';
        });

        return {
            zIndex,
            show,
            toTop,
            datalist,
            selectedIndex,
            dropdownInput,
            showList,
            hideList,
            toggleList,
        };
    }
};
</script>

<style lang="scss" scoped>
.g-dropdown {
    z-index: v-bind(zIndex);
    padding: 0;
    margin: 2px 0;
    position: relative;
    border-radius: var(--input-rounding, $dropdown-rounding);
    background-color: var(--select-bg, $dropdown-bg);

    & > div {
        z-index: 3;
        position: relative;
        fill: var(--main-text-color, $main-text-color);
        display: flex;
        align-items: center;
        border: 1px solid var(--input-border, $input-border);
        transition: border-color 0.2s;
        color: var(--main-text-color, $main-text-color);
        background-color: var(--input-bg, $input-bg);
        border-radius: var(--input-rounding, $input-rounding);

        & > label {
            position: absolute;
            height: 20px;
            font-size: var(--label-font-size, $label-font-size);
            color: var(--label-color, $label-color);
            line-height: 20px;
            letter-spacing: normal;
            padding: 0 20px;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            pointer-events: none;
            transition: all 0.2s;
        }

        & > input {
            width: 100%;
            border: none;
            flex-grow: 1;
            color: var(--main-text-color, $main-text-color);
            font-size: var(--text-md, $text-md);
            font-family: var(--font-primary, $font-primary);
            font-weight: 500;
            padding: 22px 20px 0;
            cursor: pointer;
            box-sizing: border-box;
            background-color: transparent;
            height: var(--input-height-large, $input-height-large);
            border-radius: var(--input-rounding, $input-rounding);

            &::placeholder {
                color: var(--label-color, $label-color);
                opacity: 1;
            }

            &:focus {
                outline: none;
            }
        }

        .input-btn {
            margin-right: 14px;
            font-size: 0;
            height: auto;
            transition: transform 0.2s;
        }

        &:hover {
            cursor: pointer;
        }

        &:not(.show-datalist) {
            & > .datalist {
                max-height: 0;
                border-width: 0;
                opacity: 0;

                & > output {
                    pointer-events: none;
                }
            }
        }

        &.show-datalist {
            & > .datalist {
                max-height: 300px;
            }

            .input-btn {
                transform: rotate(180deg);
            }
        }
    }

    .datalist {
        left: -1px;
        width: calc(100% + 2px);
        position: absolute;
        box-sizing: border-box;
        background-color: var(--dropdown-bg, $dropdown-bg);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        border: 1px solid var(--dropdown-list-border, $dropdown-list-border);
        transition: all 0.03s, border-width;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 1ex;
            background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
        }

        &.to-top {
            bottom: 100%;
            margin-bottom: 8px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 5px;
        }

        label {
            display: block;
            cursor: pointer;
            padding: 14px 20px;
            border-bottom: 1px solid var(--dropdown-list-border, $dropdown-list-border);
            font-weight: 400;
            font-size: var(--text-md, $text-md);
            line-height: 120%;
            color: var(--secondary, $secondary);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
                border-bottom: none;
            }

            &.active {
                pointer-events: none;
                color: var(--secondary-hover, $secondary-hover);
                font-weight: 700;
            }

            &:not(.active):hover {
                color: var(--secondary-hover, $secondary-hover);
            }
        }
    }

    &.large {
        & > div {
            & > input {
                height: var(--input-height-large, $input-height-large);
            }
        }
    }

    &.medium {
        &.active label {
            top: 13px;
        }

        &:focus-within label {
            top: 13px;
        }

        & > div {
            & > input {
                height: var(--input-height-medium, $input-height-medium);
            }
        }
    }

    &.small {
        &.active div:first-child > label {
            display: none;
        }

        &:focus-within .input-row > label {
            display: none;
        }

        & > div {
            & > input {
                height: var(--input-height-small, $input-height-small);
                font-size: var(--input-font-size-sm, $input-font-size-sm);
                padding: 0 15px 0 12px;
            }
        }

        .datalist {
            & > label {
                font-size: var(--input-font-size-sm, $input-font-size-sm);
                padding: 6px 15px 6px 12px;
            }
        }

        &:focus-within div:first-child > label {
            top: 50%;
        }
    }

    &.disabled {
        pointer-events: none;

        svg {
            fill: var(--disabled, $disabled);
        }

        & > div > input {
            pointer-events: none;
            color: var(--disabled, $disabled);
            cursor: default;

            &::placeholder {
                color: var(--disabled, $disabled);
                opacity: 1;
            }
        }
    }

    &.error {
        .input-row {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--danger, $danger);
        }

        input {
            background: var(--input-active-bg, $input-active-bg);
        }

        & > label {
            color: var(--danger, $danger);
        }

        .hint-text {
            color: var(--danger, $danger);
        }
    }

    &:focus-within {
        & > div:first-child {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--secondary, $secondary);
        }

        input {
            background: var(--input-active-bg, $input-active-bg);

            &:-webkit-autofill {
                -webkit-box-shadow: inset 0 0 0 9999px var(--input-active-bg, $input-active-bg);
            }
        }

        label {
            top: 19px;
            font-size: var(--label-focus-font-size, $label-focus-font-size);
            color: var(--primary, $primary);
        }
    }

    &.active {
        label {
            top: 19px;
            font-size: var(--label-focus-font-size, $label-focus-font-size);
        }
    }
}

.g-dropdown-error {
    & > legend {
        color: var(--danger, $danger);

        &:deep(svg) {
            fill: var(--danger, $danger);
        }
    }

    & > div > span.border {
        border-color: var(--danger, $danger);
    }
}

</style>