<template>
    <withdraw/>
</template>

<script>
import { useWalletComponent } from '@wallets';

export default {
    name: 'SendCurrency',
    components: {
        Withdraw: useWalletComponent('Withdraw'),
    }
};
</script>

<style scoped lang="scss"></style>