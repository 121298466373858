<script>
import { useWalletComponent } from '@wallets';
import { useEnvComponent } from '@environment';

export default {
    name: 'Wallets',
    extends: useEnvComponent('Layout'),
    components: {
        Environment: useWalletComponent('Environment'),
    },

    setup() {

        return {
            title: 'wallets'
        };
    },
};
</script>
