<template>
    <div class="environment">
        <g-frame>
            <div class="dashboard-grid">
                <dashboard-wallets/>
                <last-transactions class="history"/>
                <dashboard-chart/>
                <quick-withdraw />
            </div>
        </g-frame>
    </div>
</template>

<script>

import { useDashboardComponents } from '@dashboard';

export default {
    name: 'Environment',
    components: {
        ...useDashboardComponents()
    }
};
</script>

<style lang="scss" scoped>
div:deep(.g-frame) {
    padding: 2px 40px 40px;
}

.environment .no-scroll:deep(.g-frame) {
    padding-right: 40px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 365px auto 508px;
    grid-gap: 32px;
}

.wallets {
    grid-area: 1 / 1 / 2 / 2;
}

.history {
    grid-area: 1 / 2 / 2 / 4;
    overflow: hidden;
}

.dashboard-chart {
    grid-area: 2 / 1 / 3 / 3;
}

.quick-withdraw {
    grid-area: 2 / 3 / 3 / 4;
}

@media screen and (max-width: 1690px) {
    .dashboard-grid {
        gap: 16px;
        grid-template-columns: 365px auto auto;
    }

    .quick-withdraw {
        grid-area: 1 / 2 / 2 / 4;
    }

    .history {
        grid-area: 2 / 1 / 3 / 4;
    }

    .dashboard-chart {
        grid-area: 3 / 1 / 4 / 4;
    }
}

@media screen and (max-width: 1300px) {
    .dashboard-grid {
        gap: 16px;
        grid-template-columns: 365px auto 382px;
    }
}

@media screen and (max-width: 1300px) and (min-width: 1101px) {
    div:deep(.g-frame) {
        padding: 0 25px 40px;
    }
}

@media screen and (max-width: 1100px) {
    div:deep(.g-frame) {
        padding: 0 20px 40px;
    }
}

@media screen and (max-width: 1000px) {
    .dashboard-grid {
        grid-template-columns: 2fr 1fr 1fr;
    }
}

@media screen and (max-width: 767px) {
    .dashboard-grid {
        grid-template-columns: 1fr;
    }

    .wallets {
        grid-area: 1 / 1 / 2 / 2;
    }

    .quick-withdraw {
        grid-area: 2 / 1 / 3 / 2;
    }

    .history {
        grid-area: 3 / 1 / 4 / 2;
    }

    .dashboard-chart {
        grid-area: 4 / 1 / 4 / 2;
    }
}

@media screen and (max-width: 359px) {
    div:deep(.g-frame) {
        padding: 0 10px 40px;
    }
}

</style>
