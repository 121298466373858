<template>
    <div v-if="transaction.isLoaded"  class="history">
        <div class="actions">
            <g-caption size="6" weight="500">{{ $t('dashboard.history') }}</g-caption>
            <g-link-button :icon-color="'warning'" @click="$router.push({ name: 'History', ...$route.params  })">
                {{ $t('button.view_all') }}
                <g-symbol name="forward-arrow" width="20" height="20"/>
            </g-link-button>
        </div>
        <div class="history-wrapper">
            <div class="titles">
                <div class="wallet cell">{{ $t('history.wallet') }}</div>
                <div class="direction cell">{{ $t('history.direction') }}</div>
                <div class="address cell">{{ $t('history.address') }}</div>
                <div class="amount cell">{{ $t('history.amount') }}
                    <g-symbol name="arrows" width="14" height="14"/>
                </div>
                <div class="currency cell">{{ $t('history.network') }}</div>
                <div class="date cell">{{ $t('history.date') }}
                    <g-symbol name="arrows" width="14" height="14"/>
                </div>
                <div class="status cell">{{ $t('history.status') }}</div>
                <div class="confirmations cell">{{ $t('history.confirmation') }}</div>
            </div>
            <div v-for="item in transaction.state.slice(0, 5)" v-bind:key="`transaction-${item.id}`" class="item-row">
                <div class="wallet cell" data-label="Wallet">
                    <div class="wallet-wrapper">
                        <g-symbol-currency :name="item.coin.symbol.toLowerCase()" width="42" height="42"/>
                        <div class="content">{{ item.wallet.name }}</div>
                    </div>
                </div>
                <div class="direction cell" data-label="Direction">
                    <div class="content">{{ item.type }}</div>
                </div>
                <div class="address cell" data-label="address">
                    <div v-if="item.operation.address" class="address-wrap" :style="`max-width: calc(${item.operation.address.length + 2}ch + 20px`">
                        <g-copy class="content" :text="item.operation.address"/>
                    </div>
                    <div v-else class="address-wrap"></div>
                </div>
                <div class="amount cell" data-label="Amount">
                    <div class="content">{{ Number(item.operation.amount) }} {{ item.coin.symbol }}</div>
                </div>
                <div class="currency cell" data-label="Currency">
                    <div class="content">{{ item.coin.name }}</div>
                </div>
                <div class="date cell" data-label="Date">
                    <div class="content">{{ item.operation.created_at }}</div>
                </div>
                <div class="status cell" data-label="Status">
                    <g-badge :fill="getBadgeColor(item.operation.state)" size="small">
                        {{ item.operation.state || 'Waiting' }}
                    </g-badge>
                </div>
                <div class="confirmations cell" data-label="Confirmations">
                    {{ item.confirmations }}
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <g-preloader is-dark/>
    </div>
</template>

<script>

import { useShipType } from '@/Ship';
import { useHistoryAction } from '@history';

const getTransactionResource = useHistoryAction('getTransactionResource');

export default {
    name: 'LastTransactions',
    setup() {

        const status = useShipType('status');

        const transaction = getTransactionResource();

        function getBadgeColor(value) {
            switch(value) {
            case 'Sent':
            case 'Forward':
            case 'Confirmed':
                return status.success;
            case 'Error':
            case 'Canceled':
                return status.danger;
            default:
                return status.warning;
            }
        }

        return {
            getBadgeColor,
            transaction,
        };
    }
};
</script>

<style lang="scss" scoped>
.history {
    border: 1px solid var(--disabled, $disabled);
    border-radius: var(--rounding-lg, 16px);
    padding: 24px;

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
    }

    :deep(.g-button-link) {
        color: var(--main-text-color, $main-text-color);
        text-decoration: none;
    }

    :deep(.g-button-link > svg) {
        fill: var(--main-text-color, $main-text-color);
    }
}

.titles > .cell {
    font-weight: 600;
    font-size: var(--text-md, $text-md);
    line-height: 140%;
    color: var(--disabled-color, $disabled-color);

    .g-symbol {
        margin-left: 7px;
        fill: var(--disabled-color, $disabled-color);
    }
}

.item-row {
    .cell {
        color: var(--main-text-color, $main-text-color);
        font-size: var(--text-md, $text-md);
        line-height: 140%;
        font-weight: 400;

        &.wallet {
            grid-area: wlt;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            .wallet-wrapper {
                display: flex;
                align-items: center;
            }
        }

        &.direction {
            grid-area: dir;
        }

        &.date {
            grid-area: dte;
        }

        &.currency {
            grid-area: crr;
        }

        &.address {
            grid-area: adr;
        }

        &.amount {
            grid-area: amt;
            font-weight: 600;
            font-size: 16px;
        }

        &.status {
            grid-area: sts;
        }

        &.confirmations {
            grid-area: cfm;
        }
    }
}

@media (min-width: 1690px) {
    .history-wrapper {
        width: 100%;
        display: table;
        table-layout: auto;

        & > .titles,
        & > .item-row {
            display: table-row;

            &:not(:last-child) > .cell {
                border-bottom: 1px solid var(--separator-color, $separator-color);
            }

            & > .cell {
                display: table-cell;
                white-space: nowrap;
                text-align: center;
                padding: 20px 0.2vw;
                vertical-align: middle;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.wallet {
                    text-align: left;
                    white-space: normal;

                    & > div {
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }

                    .g-symbol-currency {
                        flex-shrink: 0;
                        margin-right: 16px;
                    }
                }

                &.address {
                    text-align: left;
                }

                &.amount {
                    text-align: right;
                }
            }
        }
    }

    .address-wrap {
        display: table;
        table-layout: fixed;
        width: 100%;
        font-size: var(--text-xs, $text-xs);
    }
}

@media (min-width: 851px) and (max-width: 1690px) {
    .history-wrapper > .titles,
    .history-wrapper > .item-row {
        display: grid;
        grid-template-areas:
            'wlt sts dte adr'
            'crr cfm dir amt';
        grid-template-columns:
            minmax(200px, 430px)
            minmax(100px, 140px)
            minmax(100px, 180px)
            minmax(180px, 430px);
    }
}

@media (max-width: 1689px) {
    .titles {
        display: none !important;
        background-color: red;
    }

    .item-row {
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }
    }

    .cell {
        min-height: 100%;

        &::before {
            display: block;
            content: attr(data-label);
            font-size: var(--text-xs, $text-xs);
            color: var(--disabled-color, $disabled-color);
        }

        &.wallet {
            .g-symbol-currency {
                flex-shrink: 0;
                margin-right: 16px;
            }
        }
    }
}

@media (max-width: 1300px) and (min-width: 1101px) {
    .history-wrapper {
        padding: 0 25px;
    }
}

@media screen and (max-width: 1100px) {
    .history-wrapper {
        padding: 0 20px;
    }
}

@media (max-width: 850px) {
    .item-row {
        display: grid;
        grid-template-areas:
            'wlt wlt'
            'dir dte'
            'adr adr'
            'amt amt'
            'sts cfm'
            'crr crr';
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin: 0 0 20px;
        border: 1px solid var(--separator-color, $separator-color);
        border-radius: var(--rounding-lg, $rounding-lg);
        padding: 20px;
    }
}

@media (max-width: 580px) {
    .history-wrapper {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 359px) {
    .history-wrapper {
        padding: 0 10px;
    }

    .item-row {
        padding: 10px;
    }
}

</style>
