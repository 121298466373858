<template>
    <nav class="navbar" :class="{ active: STATE.IS_SHOW_LEFTBAR }">
        <g-button class="nav-close" square size="small" @click="STATE.IS_SHOW_LEFTBAR = false">
            <g-symbol name="close" width="24" height="24"/>
        </g-button>
        <router-link
            class="link-logo"
            :class="{ active: route.name === 'Dashboard' }"
            :to="{ name: 'Dashboard', params: router.currentRoute.value.params }"
        >
            <img src="@assets/img/logo.svg" alt="LOGO" width="100"/>
        </router-link>

        <div class="navbar-devide"></div>
        <div class="linkbar" :title="route.name">
            <router-link
                class="link"
                :class="{ active: route.name === 'Dashboard' }"
                :to="{ name: 'Dashboard', params: router.currentRoute.value.params }"
            >
                <g-symbol name="home" width="20" height="20"/>
                {{ $t('part.dashboard') }}
            </router-link>
            <router-link
                class="link"
                :class="{ active: route.matched[1]?.name === 'Wallets' }"
                :to="{ name: 'Wallets', params: router.currentRoute.value.params }"
            >
                <g-symbol name="card" width="22" height="22"/>
                {{ $t('part.wallets') }}
            </router-link>
            <router-link
                class="link"
                :class="{ active: route.matched[1]?.name === 'History' }"
                :to="{ name: 'History', params: router.currentRoute.value.params }"
            >
                <g-symbol name="documents" width="22" height="22"/>
                {{ $t('part.history') }}
            </router-link>
        </div>
        <!--        <div class="linkbar">-->
        <!--            <router-link-->
        <!--                class="link"-->
        <!--                :class="{ active: route.name === 'Settings' }"-->
        <!--                :to="{ name: 'Settings', params: { locale: $i18n.locale } }"-->
        <!--            >-->
        <!--                <g-symbol name="info" width="20" height="20"/>-->
        <!--                {{ $t('part.support') }}-->
        <!--            </router-link>-->
        <!--        </div>-->
    </nav>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { STATE } from '@/Ship';

export default {
    name: 'EnvNavbar',
    setup() {
        return {
            STATE,
            route: useRoute(),
            router: useRouter(),
        };
    },
};
</script>

<style lang="scss" scoped>
nav {
    width: var(--navbar-width, $navbar-width);
    padding: 44px 24px 16px;
    background-color: var(--bar-bg, $bar-bg);
    display: flex;
    flex-direction: column;
}

.navbar-devide {
    width: 100%;
    height: 1px;
    background: var(--separator-color, $separator-color);
    margin: 30px 0 13px;
}

.linkbar {
    width: 100%;
    padding: 20px 0;

    & + .linkbar {
        margin-top: auto;
    }

    .link {
        font-size: var(--text-sm, $text-sm);
        margin: 8px 0;
        padding: 14px 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        overflow: visible;
        position: relative;
        transition: all 0.2s;
        fill: var(--navbar-colorr, $navbar-color);
        color: var(--navbar-color, $navbar-color);
        text-decoration: none;
        border-radius: 12px;

        &:deep(svg) {
            width: 24px;
            margin-right: 10px;
        }

        &:hover,
        &.active {
            fill: var(--navbar-active-color, $navbar-active-color);
            color: var(--navbar-active-color, $navbar-active-color);
            background: var(--primary, $primary);
        }

        &.active {
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 1300px) and (min-width: 1101px) {
    nav {
        padding: 44px 20px 16px;
    }
}

@media screen and (min-width: 1101px) {
    .nav-close {
        display: none !important;
    }
}

@media screen and (max-width: 1100px) {
    .nav-close {
        position: absolute;
        top: 25px;
        right: 20px;
    }

    .navbar {
        position: absolute;
        left: -250px;
        top: 0;
        width: 250px;
        height: 100%;
        max-height: 100vh;
        overflow: auto;
        z-index: 100;
        transition: left 0.2s;

        &.active {
            left: 0;
        }
    }
}
</style>
