<template>
    <div
        class="g-textarea"
        :class="[{ disabled: disabled}]"
    >
        <div class="row">
            <label>
                <textarea v-bind:value="modelValue"
                          v-on:input="inputEvent"
                          v-bind="$attrs"
                          :disabled="disabled"
                          :placeholder="label"
                ></textarea>
                <span>{{ label }}</span>
                <slot/>
            </label>
            <button v-if="modelValue.length > 0" class="button-close" @click="reset">
                <g-symbol name="close" width="24" height="24"/>
            </button>
        </div>
        <div v-if="hint" class="hint-text">{{ hint }}</div>
    </div>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
    name: 'GTextarea',
    inheritAttrs: false,
    props: {
        modelValue: {type: String},
        label: {type: String},
        hint: {type: String},
        heightTextarea: {type: String, default: '74'},
        disabled: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue'],
    setup(props, {emit}) {
        const inputEvent = event => emit('update:modelValue', event.target.value);

        const {heightTextarea} = toRefs(props);
        const textAreaHeight = computed(() => {
            return heightTextarea.value + 'px';
        });

        const reset = () => emit('update:modelValue', '');

        return {
            textAreaHeight,
            inputEvent,
            reset
        };
    }
};
</script>

<style lang="scss" scoped>
.g-textarea {
    position: relative;
    margin: 2px 0;
    padding: 0;

    .row {
        border: 1px solid var(--input-border, $input-border);
        transition: border-color 0.2s;
        display: flex;
        align-items: center;
        fill: var(--secondary, $secondary);
        background-color: var(--input-bg, $input-bg);
        border-radius: var(--textarea-rounding, $textarea-rounding);
        height: v-bind(textAreaHeight);
    }

    label {
        padding-top: 45px;
        display: block;
        width: 100%;
        height: 100%;
        cursor: text;
        position: relative;
    }

    textarea + span {
        position: absolute;
        height: 20px;
        font-size: var(--label-font-size, $label-font-size);
        color: var(--label-color, $label-color);
        line-height: 20px;
        letter-spacing: normal;
        padding: 0 20px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 21px;
        left: 0;
        white-space: nowrap;
        pointer-events: none;
        transition: all 0.2s;
    }

    textarea {
        width: 100%;
        height: 100%;
        border: none;
        flex-grow: 1;
        font-size: var(--input-font-size, $input-font-size);
        line-height: 140%;
        color: var(--main-text-color, $main-text-color);
        font-weight: 400;
        font-family: var(--font-primary, $font-primary);
        padding: 5px 20px 0;
        box-sizing: border-box;
        background-color: var(--input-bg, $input-bg);
        border-radius: var(--textarea-rounding, $textarea-rounding);
        resize: none;

        &::placeholder {
            color: transparent;
            opacity: 0;
        }

        &:not(:placeholder-shown) + span {
            top: 20px;
        }

        &:focus + span {
            font-size: var(--label-focus-font-size, $label-focus-font-size);
        }

        &:focus {
            outline: none;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: inset 0 0 0 9999px white;
        }
    }

    &:deep(svg) {
        margin-right: 11px;
    }

    &.disabled {
        .row {
            background-color: var(--input-bg, $input-bg);
            pointer-events: none;
            opacity: 0.5;
        }

        textarea {
            color: var(--label-disabled-color, $label-disabled-color);
            background-color: var(--input-bg, $input-bg);
        }

        .row span:first-child {
            color: var(--label-disabled-color, $label-disabled-color);
        }
    }

    &.error {
        .row {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--danger, $danger);
            box-shadow: 0 0 0 1px var(--danger, $danger);
        }

        textarea {
            background: var(--input-active-bg, $input-active-bg);
        }

        textarea + span {
            color: var(--danger, $danger);
        }

        .hint-text,
        .textarea + span {
            color: var(--danger, $danger);
        }
    }

    &:focus-within {
        .row {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--secondary, $secondary);
            box-shadow: 0 0 0 1px var(--secondary, $secondary);
        }

        textarea {
            background: var(--input-active-bg, $input-active-bg);
        }

        textarea + span {
            top: 20px;
            font-size: var(--label-focus-font-size, $label-focus-font-size);
            color: var(--primary, $primary);
        }
    }
}

.button-close {
    position: absolute;
    right: 21px;
    top: 23px;
    fill: var(--main-text-color, $main-text-color);
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;

    &:hover {
        fill: var(--primary, $primary);
    }

    svg {
        margin-right: 0;
    }
}

.hint-text {
    font-size: var(--text-xs, $text-xs);
    line-height: 140%;
    font-weight: 400;
    color: var(--input-hint-color, $input-hint-color);
    margin-top: 6px;
}

</style>
