import { useWalletResource } from '@wallets';

export default () => {
    const coin = useWalletResource('Coin');
    if (!coin.isLoaded) {
        coin.load();
    }

    return coin;
};
