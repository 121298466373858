import componentLoader from '@/Ship/core/ComponentLoader';
import actionLoader from '@/Ship/core/ActionLoader';
//import TaskLoader from "@/Ship/core/TaskLoader";
import resourceLoader from '@/Ship/core/ResourceLoader';

export function useWalletAction(ask) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).action(ask);
}

export function useWalletActions() {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).list();
}

// export function useWalletTask(ask) {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).task(ask);
// }

// export function useWalletTasks() {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).list();
// }

export function useWalletResource(ask) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).resource(ask);
}

export function useWalletResources() {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).list();
}

export function useWalletComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useWalletComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export default require('./View').default;
