<template>
    <span class="g-tooltip" ref="gTooltip"></span>
</template>

<script>
import { nextTick, ref, watch } from 'vue';

export default {
    name: 'GHint',
    props: {
        tooltipText: {
            type: String,
            default: '',
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
        hoverTooltip: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const gTooltip = ref(null);

        watch(() => props.showTooltip, () => {
            nextTick(() => {
                const parentNode = gTooltip.value.parentNode;
                if (props.showTooltip) {
                    addTooltip(parentNode, props.tooltipText);
                } else {
                    removeTooltip(parentNode, props.tooltipText);
                }
            });
        });
        watch(() => props.hoverTooltip, () => {
            nextTick(() => {
                if (props.hoverTooltip) {
                    const parentNode = gTooltip.value.parentNode;
                    tooltipOnHover(parentNode, props.tooltipText);
                }

            });
        }, { immediate: true });

        const tooltipOnHover = (el, binding) => {
            el.addEventListener('mouseenter', function () {
                let $tooltip = document.createElement('div');
                let $tooltipDimension = el.getBoundingClientRect();
                $tooltip.setAttribute('class', 'tooltip');
                $tooltip.setAttribute('id', 'TooltipId');
                $tooltip.innerHTML = binding;
                $tooltip.style.left = $tooltipDimension.left + ($tooltipDimension.width / 2) + 'px';
                $tooltip.style.top = $tooltipDimension.top - 30 + 'px';
                document.body.appendChild($tooltip);
            });
            el.addEventListener('mouseleave', function () {
                let elemToRemove = document.getElementById('TooltipId');
                elemToRemove.parentNode.removeChild(elemToRemove);
            });
        };

        const addTooltip = (el, binding) => {
            let $tooltip = document.createElement('div');
            let $tooltipDimension = el.getBoundingClientRect();
            $tooltip.setAttribute('class', 'tooltip');
            $tooltip.setAttribute('id', 'TooltipId');
            $tooltip.innerHTML = binding;
            $tooltip.style.left = ($tooltipDimension.width / 2) + 'px';
            $tooltip.style.top = -32 + 'px';
            el.appendChild($tooltip, $tooltipDimension.width);
        };

        const removeTooltip = () => {
            let elemToRemove = document.querySelectorAll('.tooltip');
            elemToRemove[0].remove();
        };

        return {
            gTooltip,
            tooltipOnHover,
            addTooltip,
            removeTooltip
        };
    }
};
</script>

<style lang="scss"></style>
