<template>
    <form @submit.prevent="sendTransaction">
        <g-input :label="$t('label.from_address')" v-bind:value="wallet.model.address" readonly/>

        <g-input :label="$t('label.to_address')" v-model="payload.toAddress" required>
            <g-symbol v-if="payload.toAddress.length" name="close" width="24" height="24" class="reset" @click="payload.toAddress = ''"/>
        </g-input>


        <div class="row">
                <g-input
                    v-model="payload.amount"
                    :label="$t('label.amount')"
                    step="any"
                    type="number"
                    required>
                    <g-symbol-currency :name="wallet.model.coin && wallet.model.coin.symbol.toLowerCase()" width="26" height="26"/>
                    <div class="button-wrap">
                        <g-button type="button" size="small" square @click="pasteAmount">
                            <g-symbol name="all" width="16" height="16"/>
                        </g-button>
                        <g-tooltip :tooltip-text="$t('label.maxAmount')"
                               hover-tooltip></g-tooltip>
                    </div>
                </g-input>

            <g-select v-model="payload.fee"
                      v-bind:label="$t('label.fee')"
                      :hint="$t('inputHint.fee')"
                      v-bind:options="feeOptions"
                      option-text="label"
                      option-value="value"
                      size="large"/>
        </div>

        <g-button fill="primary" size="large" :disabled="!isAllow">
            <g-symbol name="check" width="24" height="24"/>
            {{ $t('button.send') }}
        </g-button>
    </form>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useWalletResource } from '@wallets';
import GButton from '@/Ship/components/global/button/GButton';

export default {
    name: 'Withdraw',
    components: { GButton },
    setup() {

        const wallet = useWalletResource('Wallet');
        const isShowTooltip = ref(false);

        const payload = reactive({
            toAddress: '',
            amount: '',
            fee: '',
        });

        const feeOptions = [
            { value: '0.02', label: 'Fee 1', id: 1 },
            { value: '0.01', label: 'Fee 2', id: 2 },
            { value: '0.005', label: 'Fee 3', id: 3 },
        ];


        function sendTransaction() {
            PopUp.open(require('@wallets/components/parts/SendDetail')).setCaption(i18n.global.getLocaleMessage(i18n.global.locale.value).popupTitle.send_details).setPayload({ payload: {
                ...payload,
                walletId: wallet.model.id,
                address: wallet.model.address,
                currency: wallet.model.coin.symbol,
                name: wallet.model.name,
                type: wallet.model.type,
            }});
        }

        const isAllow = computed(() => {
            return payload.amount <= Number(wallet.model.balance) && payload.amount > 0;
        });

        function pasteAmount(){
            payload.amount = wallet.model.balance;
        }

        return {
            wallet,
            isShowTooltip,
            payload,
            isAllow,
            feeOptions,
            sendTransaction,
            pasteAmount
        };
    }
};
</script>

<style lang="scss" scoped>
.g-input {
    margin-bottom: 16px;

    &:deep(svg.reset + svg) {
        margin-left: -8px;
    }

    .g-symbol-currency {
        flex-shrink: 0;
    }

    .button-wrap {
        margin-right: 14px;
        flex-shrink: 0;
    }

    .g-button {
        margin-top: 0;

        &:deep(svg) {
            margin: 0;
        }
    }

    .g-symbol-currency + .g-button {
        margin-left: -8px;
    }

    .reset {
        cursor: pointer;

        &:hover {
            fill: var(--main-text-color, $main-text-color);
        }
    }
}

.g-button {
    width: 100%;
    margin-top: 8px;
}

.row {
    display: flex;
    gap: 16px;

    & > .g-input,
    & > .g-select {
        margin-top: 0;
        width: 50%;
    }
}

@media (max-width: 1000px) and (min-width: 500px) {
    .row {
        flex-direction: column;
        gap: 0;

        & > .g-input,
        & > .g-select {
            margin-top: 0;
            width: 100%;
        }
    }
}

@media (max-width: 500px) {
    .row {
        flex-direction: column;
        gap: 0;

        & > .g-input,
        & > .g-select {
            margin-top: 0;
            width: 100%;
        }
    }
}
</style>