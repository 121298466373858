import { defineAsyncComponent, shallowRef } from 'vue';

class PopUpManager  {
    static #instance = null;
    caption = '';
    payload = {};
    component = shallowRef(null);

    constructor() {
        if (!PopUpManager.#instance) {
            PopUpManager.#instance = this;
        }
        return PopUpManager.#instance;
    }

    static getInstance() {
        return new PopUpManager();
    }

    setCaption(caption) {
        this.caption = caption;

        return this;
    }

    setPayload(payload) {
        this.payload = payload;


        return this;
    }

    open(popUpPath) {
        this.payload = {};
        this.component.value = defineAsyncComponent(() => new Promise(resolve => {
            resolve(popUpPath);
        }));

        return this;
    }

    close() {
        this.component.value = null;
        this.payload = {};
        this.caption = null;

        return this;
    }

}

export default PopUpManager.getInstance();
