import { STACK, ApiClient } from '@/Ship';
import { useProfileResource } from '@profile';

export default function (item) {
    STACK.push(() => {
        return ApiClient.put(`profile/regenerate-api_key/${item.id}`);
    }).then(response => {
        const key = response.data.secret;
        const client = useProfileResource('Client');
        const index = client.state.indexOf(item);
        client.state[index].secret = key;
    });
}
