<template>
    <form class="sign-up"  v-on:submit.prevent="registration">
        <div>
            <g-caption size="4" weight="700">{{ $t('page.sign_up') }}</g-caption>
            <div class="subtitle">{{ $t('authentication.sign_sub_title') }}</div>
        </div>

        <div>
            <g-input name="first_name" v-model="firstName" v-bind:label="$t('label.first_name')"/>
            <g-input name="last_name" v-model="lastName" v-bind:label="$t('label.last_name')"/>
            <g-input  name="email" v-model="email" v-bind:label="$t('label.email')" type="email"/>
            <div class="subtitle">{{ $t('authentication.reset_sub_title_password') }}</div>
            <password-input v-model="password" v-model:complex="isComplex" v-bind:label="$t('label.password')"/>
        </div>

        <div>
            <g-checkbox v-model:checked="isTermsAgree" v-bind:label="$t('authentication.sign_agree_1')"/>
            <router-link :to="{ name: 'Api', params: router.currentRoute.value.params }" class="link">
                    {{ $t('authentication.sign_agree_2') }}
            </router-link>
            {{ $t('authentication.sign_agree_3') }}
            <router-link :to="{ name: 'Api', params: router.currentRoute.value.params }" class="link">
                {{ $t('authentication.sign_agree_4') }}
            </router-link>
        </div>

        <g-button fill="primary" size="large" v-bind:disabled="!isComplex || !isTermsAgree">
            {{ $t('button.create_account') }}
        </g-button>

        <div>
            {{ $t('authentication.sign_have_account') }}
            <router-link :to="{ name: 'Dashboard', params: router.currentRoute.value.params }" tag="a" className="link">
                {{ $t('base.sign_in') }}
            </router-link>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthenticationComponent, useAuthenticationActions } from '@auth';

const { signUp, signIn } = useAuthenticationActions();

export default {
    name: 'SignUp',
    components: {
        PasswordInput: useAuthenticationComponent('PasswordInput')
    },
    setup() {
        const firstName = ref('');
        const lastName = ref('');
        const email = ref('');
        const password = ref('');

        const isComplex = ref(false);
        const isTermsAgree = ref(false);

        const router = useRouter();

        function registration() {
            signUp({
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
                password: password.value,
            }).then(() => {
                signIn(email.value, password.value).then(() => {
                    router.push({ name: 'Dashboard', params: router.currentRoute.value.params });
                });
            });
        }
        return {
            firstName,
            lastName,
            email,
            password,
            router,
            isComplex,
            isTermsAgree,
            registration,
        };
    },
};
</script>

<style lang="scss" scoped>
.sign-up {
    padding: 0 18px;
    max-width: 460px;
    box-sizing: border-box;
    text-align: center;

    & > div {
        margin: 25px 0;
    }
}

.g-checkbox {
    vertical-align: top;
}

.g-button {
    width: 100%;
}

.g-input,
.password-input {
    margin: 8px 0 10px;
}

.link {
    margin: 0 5px;
    font-size: 106%;
    font-weight: 600;
    white-space: nowrap;
    color: var(--secondary, $secondary);
    transition: color 0.2s;

    &:hover {
        color: var(--primary, $primary);
    }
}

.subtitle {
    color: var(--home-page-sub-title-color, $home-page-sub-title-color);
}
</style>
