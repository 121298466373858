
import { reactive } from 'vue';
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();

export default class Transaction extends BaseResource {
    static #instance = null;
    endpoint = '/transactions';

    filters = reactive({});

    constructor() {
        super();
        if (!Transaction.#instance) {
            Transaction.#instance = this;
        }
        return Transaction.#instance;
    }

    static getInstance() {
        return new Transaction();
    }
}
