<template>
    <div class="remove-profile">
        <div class="text">{{ $t('profile.popupFaq') }}</div>
        <div class="footer">
            <g-button icon-color="danger" @click="closePopup">
                <g-symbol name="close" width="24" height="24"/>
                {{ $t('button.cancel') }}
            </g-button>
            <g-button fill="danger" v-on:click="deleteProfile">
                <g-symbol name="delete" width="24" height="24"/>
                {{ $t('button.delete') }}
            </g-button>
        </div>
    </div>
</template>

<script>

import { useProfileAction } from '@profile';
import { PopUpManager as PopUp } from '@/Ship';

export default {
    name: 'RemoveProfile',

    emits: ['performed'],

    setup(props,{emit}) {

        function closePopup() {
            emit('performed');
        }

        return {
            closePopup,
            deleteProfile: useProfileAction('deleteProfile'),
        };
    }
};
</script>

<style scoped lang="scss">
.text {
    font-size: var(--text-lg, $text-lg);
    line-height: 150%;
}

.footer {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-top: 32px;

    &:deep(.g-button) {
        width: 100%;
    }
}

@media screen and (max-width: 460px) {
    .g-popup .footer {
        flex-direction: column;
        gap: 12px;
    }
}
</style>
