import { useWalletResource } from '@wallets';

export default () => {
    const wallet = useWalletResource('Wallet');
    if (!wallet.isLoaded) {
        wallet.setIncludes(['coin']);
        wallet.load();
    }

    return wallet;
};
