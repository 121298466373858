<template>
    <div class="quick-withdraw">
        <div class="title">{{ $t('dashboard.withdraw') }}</div>
        <withdraw/>
    </div>
</template>

<script>
import { useWalletComponent } from '@wallets';

export default {
    name: 'QuickWithdraw',
    components: {
        Withdraw: useWalletComponent('Withdraw'),
    },
};
</script>

<style lang="scss" scoped>
.quick-withdraw {
    border: 1px solid var(--disabled, $disabled);
    border-radius: var(--rounding-lg, $rounding-lg);
    padding: 24px;

    .title {
        font-weight: 600;
        font-size: var(--text-lg, $text-lg);
        line-height: 150%;
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 359px) {
    .quick-withdraw {
        padding: 12px;
    }
}
</style>