import actionLoader from './core/ActionLoader';
import componentLoader from './core/ComponentLoader';
//import TaskLoader from "./core/TaskLoader";
// import popUpLoader from './core/PopUpLoader';
import resourceLoader from '@/Ship/core/ResourceLoader';
import typeLoader from '@/Ship/core/TypeLoader';

function useShipResource(ask) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).resource(ask);
}

function useShipResources() {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).list();
}

function useShipAction(ask) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).action(ask);
}

function useShipActions() {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).list();
}

// function useShipTask(ask) {
//     const requireTasks = require.context("./tasks", true, /[\w-]+\.js$/)
//     return TaskLoader(requireTasks).task(ask);
// }

// function useShipTasks() {
//     const requireTasks = require.context("./tasks", true, /[\w-]+\.js$/)
//     return TaskLoader(requireTasks).list();
// }

function useShipComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

function useShipComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

function useShipType(ask) {
    const requireTypes = require.context('./enums', true, /[\w-]+\.js$/);
    return typeLoader(requireTypes).type(ask);
}

function useShipTypes() {
    const requireTypes = require.context('./enums', true, /[\w-]+\.js$/);
    return typeLoader(requireTypes).list();
}

// function usePopUpComponents() {
//     const requirePopUp = require.context('@/', true, /[\w-]+\.vue$/);
//     return popUpLoader(requirePopUp);
// }

const Router = require('./router').default;
const i18n = require('./setup/Localization').default;
const ApiClient = require('./setup/ApiClient').default;
const ErrorManager = require('./core/ErrorManager').default;
const PopUpManager = require('./core/PopUpManager').default;
//const AutoSaveForm = require('./core/AutoSaveForm').default;
const ValidRules = require('./core/ValidRules').default;
const Validator = require('./core/Validator').default;
//const WebSockets = require("./core/WebSockets").default;
const STATE = require('./state').default;
const RootView = require('./App').default;
const STACK = require('./core/RequestStack').default;

export {
    i18n,
    STATE,
    STACK,
    Router,
    RootView,
    ApiClient,
    ValidRules,
    ErrorManager,
    PopUpManager,
    //WebSockets,
    Validator,
    //AutoSaveForm,
    // useShipTask,
    //useShipTasks,
    useShipResource,
    useShipResources,
    useShipAction,
    useShipActions,
    useShipComponent,
    useShipComponents,
    useShipType,
    useShipTypes,
    // usePopUpComponents,
};
