<template>
    <div class="environment">
        <history-filter/>
        <g-frame>
            <history/>
        </g-frame>
    </div>
    <div class="router-wrap" :class="{'active-page': route.name === 'Transaction'}">
        <transition name="route" mode="out-in">
            <router-view class="rightbar"/>
        </transition>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { STATE } from '@/Ship';
import { useHistoryComponents } from '@history';

const {
    History,
    HistoryFilter,
} = useHistoryComponents();

export default {
    name: 'Environment',
    components: {
        History,
        HistoryFilter,
    },

    setup() {
        onMounted(() => {
            STATE.IS_SHOW_LEFTBAR = false;
        });
        return {
            route: useRoute(),
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    padding: 2px 0 0;
    min-width: 0;

    .history-filter {
        padding: 0 40px;
    }

    .frame-wrapper {
        height: calc(100% - 103px);
    }
}

.router-wrap {
    transition: width 0.3s;
    width: 0;
    flex-shrink: 0;

    &.active-page {
        width: var(--rightbar-width, $rightbar-width);
    }
}

.history-filter {
    margin-bottom: 38px;
}

.rightbar {
    z-index: 2;
    height: 100%;
}

.route-enter-active {
    transition: opacity 0.7s, transform 0.3s;
}

.route-enter-from,
.route-leave-active {
    opacity: 0;
    transition: opacity 0.7s, transform 0.3s;
    transform: translateX($rightbar-width);
}

@media screen and (max-width: 1300px) and (min-width: 1101px) {
    .environment .history-filter {
        padding: 0 25px;
    }
}

@media screen and (max-width: 1100px) {
    .environment .history-filter {
        padding: 0 20px;
    }

    .router-wrap {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 101;
        max-height: 100vh;
        overflow: hidden;
        max-width: var(--rightbar-width, $rightbar-width);

        &.active-page {
            width: 100%;
        }
    }
}

@media screen and (max-width: 767px) {
    .environment {
        .frame-wrapper {
            height: calc(100% - 169px);
        }
    }
}

@media screen and (max-width: 359px) {
    .environment .history-filter {
        padding: 0 10px;
    }
}
</style>
