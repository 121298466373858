import componentLoader from '@/Ship/core/ComponentLoader';
//import actionLoader from "@/Ship/core/ActionLoader";
//import TaskLoader from "@/Ship/core/TaskLoader";
//import resourceLoader from "@/Ship/core/ResourceLoader";

// export function useDashboardAction(ask) {
//     const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
//     return actionLoader(requireActions).action(ask);
// }

// export function useDashboardActions() {
//     const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
//     return actionLoader(requireActions).list();
// }

// export function useDashboardTask(ask) {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).task(ask);
// }

// export function useDashboardTasks() {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).list();
// }

// export function useDashboardResource(ask) {
//     const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
//     return resourceLoader(requireResources).resource(ask);
// }

// export function useDashboardResources() {
//     const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
//     return resourceLoader(requireResources).list();
// }

export function useDashboardComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useDashboardComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export default require('./View').default;
