<template>
    <div
        :class="['g-badge', size, {[`${stroke}-stroke`]: stroke}, {[`${fill}-fill`]: fill}]"
    >
        <span v-if="item">{{ title }}</span>
        <slot v-else/>
        <g-symbol v-if="close" name="close-bg" width="24" height="24" @click="$emit('remove', item)"/>
    </div>
</template>

<script>
export default {
    name: 'GBadge',
    props: {
        fill: {
            type: String
        },
        stroke: {
            type: String
        },
        item: {
            type: Object
        },
        title: {
            type: String
        },
        close: {
            type: Boolean,
            default: false
        },
        size: {
            default: 'large'
        },
    }
};
</script>

<style lang="scss" scoped>
.g-badge {
    border-radius: var(--badge-rounding, $badge-rounding);
    height: var(--badge-height, $badge-height);
    padding: 2px 18px;
    color: var(--main-text-color, $main-text-color);
    font-weight: 400;
    font-size: var(--badge-font-size, $badge-font-size);
    background: var(--badge-bg, $badge-bg);
    border: 1px solid var(--badge-border, $badge-border);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    & > svg {
        width: 24px;
        height: 24px;
        min-width: 16px;
        margin-left: 8px;
        cursor: pointer;
        transition: all 0.2s;
        margin-right: -8px;
    }

    &.small {
        height: var(--badge-height-sm, $badge-height-sm);
        padding: 2px 10px;

        & > svg {
            width: 16px;
            height: 16px;
            margin-left: 4px;
            margin-right: 0;
        }
    }

    &.primary-fill,
    &.secondary-fill,
    &.success-fill,
    &.danger-fill,
    &.warning-fill {
        border: none;
    }

    &.primary-fill {
        background-color: var(--badge-primary-bg, $badge-primary-bg);
        color: var(--primary, $primary);

        &:deep(svg) {
            fill: var(--primary, $primary);
        }
    }

    &.secondary-fill {
        background-color: var(--badge-secondary-bg, $badge-secondary-bg);
        border-color: var(--badge-secondary-bg, $badge-secondary-bg);
        color: var(--secondary, $secondary);

        &:deep(svg) {
            fill: var(--secondary, $secondary);
        }
    }

    &.success-fill {
        background-color: var(--badge-success-bg, $badge-success-bg);
        color: var(--success, $success);

        &:deep(svg) {
            fill: var(--success, $success);
        }
    }

    &.danger-fill {
        background-color: var(--badge-danger-bg, $badge-danger-bg);
        color: var(--danger, $danger);

        &:deep(svg) {
            fill: var(--danger, $danger);
        }
    }

    &.warning-fill {
        background-color: var(--badge-warning-bg, $badge-warning-bg);
        color: var(--warning, $warning);

        &:deep(svg) {
            fill: var(--warning, $warning);
        }
    }

    &.primary-stroke,
    &.secondary-stroke,
    &.success-stroke,
    &.danger-stroke,
    &.warning-stroke {
        background: white;
    }

    &.primary-stroke {
        border: 1px solid var(--primary, $primary);
        color: var(--primary, $primary);

        &:deep(svg) {
            fill: var(--primary-hover, $primary-hover);
        }
    }

    &.secondary-stroke {
        border: 1px solid var(--secondary, $secondary);
        color: var(--secondary, $secondary);

        &:deep(svg) {
            fill: var(--secondary-hover, $secondary-hover);
        }
    }

    &.success-stroke {
        border: 1px solid var(--success, $success);
        color: var(--success, $success);

        &:deep(svg) {
            fill: var(--success-hover, $success-hover);
        }
    }

    &.danger-stroke {
        border: 1px solid var(--danger, $danger);
        color: var(--danger, $danger);

        &:deep(svg) {
            fill: var(--danger, $danger);
        }
    }

    &.warning-stroke {
        border: 1px solid var(--warning, $warning);
        color: var(--warning, $warning);

        &:deep(svg) {
            fill: var(--warning, $warning);
        }
    }

    &.disabled,
    &:disabled {
        pointer-events: none;
    }
}
</style>
