<template>
    <div class="g-date-picker">
        <ul class="presets">
            <li v-for="preset of presets"
                v-bind:key="preset"
                v-on:click="onPresetSelect(preset)">
                {{ $t(`date.${preset}`) }}
            </li>
        </ul>
        <DatePicker is-range
                    v-model="dateRange"
                    v-bind:locale="locale"
                    v-on:update:model-value="onSetRange"/>
    </div>
</template>

<script>

import { computed, ref, } from 'vue';
import { i18n } from '@/Ship';

export default {
    name: 'GDatePicker',
    props: ['from', 'to'],
    emits: ['update:from', 'update:to', 'change'],

    setup(props, { emit }) {
        const dateRange = ref({
            start: props.from || null,
            end: props.to || null,
        });

        const locale = computed(() => {
            return {
                id: i18n.global.locale.value,
                firstDayOfWeek: {
                    en: 1,
                    ru: 2,
                    pl: 2,
                }[i18n.global.locale.value],
            };
        });

        const presets = [
            'today',
            'yesterday',
            'curren_week',
            'previous_week',
            'current_month',
            'previous_month',
        ];

        const onPresetSelect = (preset) => {
            let start;
            let end = new Date();
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            switch (preset) {
            case 'today':
                start = today;
                break;
            case 'yesterday':
                start = new Date(today.getTime() - 24 * 60 * 60 * 1000);
                end.setDate(start.getDate());
                //end.setHours(23, 59, 59);
                break;
            case 'curren_week':
                start = new Date(today.setDate(today.getDate() - today.getDay()));
                break;
            case 'previous_week':
                end = new Date(today.setDate(today.getDate() - today.getDay()));
                start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
                //end.setHours(23, 59, 59);
                break;
            case 'current_month':
                start = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
            case 'previous_month':
                start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                end = new Date(today.getFullYear(), today.getMonth(), 0);
                //end.setHours(23, 59, 59);
                break;
            }

            dateRange.value = { start, end };
        };

        function onSetRange() {
            dateRange.value.start.setHours(0, 0, 0);
            dateRange.value.end.setHours(23, 59, 59);

            emit('update:from', dateRange.value.start);
            emit('update:to', dateRange.value.end);
            emit('change');
        }


        return {
            locale,
            dateRange,
            presets,
            onSetRange,
            onPresetSelect,
        };

    }
};
</script>

<style lang="scss" scoped>
@media (min-width: 451px) {
    .vc-container {
        padding: 16px 14px 10px;
    }
}

@media (max-width: 450px) {
    .presets {
        display: none;
    }

    .vc-container {
        padding: 10px;
    }
}

.g-date-picker {
    width: 100%;
    max-width: 440px;
    display: flex;
    align-items: stretch;
    border-radius: 8px;
    border: 1px solid var(--secondary, $secondary);
    box-shadow: var(--box-shadow, $box-shadow);
    background-color: var(--dropdown-bg, $dropdown-bg);
}

.presets {
    padding: 18px;

    & > li {
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 10px;
        white-space: nowrap;
        color: var(--secondary, $secondary);

        &:hover {
            color: var(--secondary, $secondary-hover);
        }
    }
}

:deep(.highlighted) {
    background-color: var(--secondary, #{$secondary});
}

:deep(.highlighted-text) {
    color: var(--date-picker-bg, $date-picker-bg);
}

:deep(.vc-container) {
    --text-xs: var(--date-field-text-lg, #{$date-picker-text-xs});
    --text-sm: var(--date-field-text-lg, #{$date-picker-text-sm});
    --text-base: var(--date-field-text-lg, #{$date-picker-text-base});
    --text-lg: var(--date-field-text-lg, #{$date-picker-text-lg});
    --blue-100: var(--date-picker-primary, #{$date-picker-primary});
    --blue-200: var(--secondary, #{$secondary});
    --blue-300: var(--date-picker-primary, #{$date-picker-primary});
    --blue-400: var(--date-picker-primary, #{$date-picker-primary});
    --blue-500: var(--date-picker-primary, #{$date-picker-primary});
    --blue-600: var(--date-picker-primary-hover, #{$date-picker-primary-hover});
    --blue-700: var(--date-picker-primary-hover, #{$date-picker-primary-hover});
    --blue-800: var(--date-picker-primary-hover, #{$date-picker-primary-hover});
    --blue-900: var(--date-picker-primary-hover, #{$date-picker-primary-hover});
    --gray-100: var(--date-picker-secondary, #{$date-picker-secondary-hover});
    --gray-200: var(--date-picker-secondary-hover, #{$date-picker-secondary-hover});
    --gray-300: var(--date-picker-secondary-hover, #{$date-picker-secondary-hover});
    --gray-400: var(--date-picker-secondary-hover, #{$date-picker-secondary-hover});
    --gray-500: var(--date-picker-secondary, #{$date-picker-secondary});
    --gray-600: var(--date-picker-secondary, #{$date-picker-secondary});
    --gray-700: var(--date-picker-secondary, #{$date-picker-secondary});
    --gray-800: var(--date-picker-secondary, #{$date-picker-secondary});
    --gray-900: var(--date-picker-secondary, #{$date-picker-secondary});
    --day-border: 1px solid var(--date-picker-secondary-hover, #{$date-picker-secondary-hover});
    --day-border-highlight: 1px solid var(--date-picker-secondary-hover, #{$date-picker-secondary-hover});
    --weekday-bg: var(--date-picker-bg, $date-picker-bg);
    --weekday-border: 0;
    --day-width: 90px;
    --day-height: 90px;

    border-radius: var(--date-picker-rounding, $date-picker-rounding);
    background-color: var(--date-picker-bg, $date-picker-bg);
    color: var(--date-picker-color, $date-picker-color);
    font-family: var(--font-main, $font-primary);
    width: 100%;
    position: relative;
    border: none;

    .vc-header {
        background-color: var(--date-picker-bg, $date-picker-bg);
        padding: 10px 0;
    }

    & .vc-weeks {
        padding: 0;
    }

    & .vc-weekday {
        background-color: var(--weekday-bg);
        border-bottom: var(--weekday-border);
        border-top: var(--weekday-border);
        padding: 5px 0;
    }

    & .vc-day {
        text-align: left;
    }

    & .vc-day-dots {
        margin-bottom: 5px;
    }

    & .vc-pane-container > div {
        &:nth-of-type(3) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .vc-arrow > svg > path {
        fill: var(--secondary, $secondary);
    }
}
</style>