import { STACK, ApiClient } from '@/Ship';
import { useProfileResource } from '@profile';

const client = useProfileResource('Client');

export default function () {
    STACK.push(() => {
        return ApiClient.post('profile/api_clients');
    }).then(response => {
        client.state.push(response);
    });
}
