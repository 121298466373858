import { camelCase, upperFirst } from 'lodash';

export default function (requireComponents) {
    return {
        list() {
            let componentList = {};
            requireComponents.keys().forEach(fileName => {
                const componentConfig = requireComponents(fileName);
                const componentName = upperFirst(
                    camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
                );
                componentList[componentName] = componentConfig.default || componentConfig;
            });

            return componentList;
        },
        component(ask) {
            const component = requireComponents.keys().find(fileName => {
                return new RegExp(`${ask}.vue`).test(fileName);
            });
            const componentConfig = requireComponents(component);

            return componentConfig.default || componentConfig;
        }
    };
}