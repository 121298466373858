<template>
    <div class="history-wrapper">
        <div class="titles">
            <div class="wallet cell">{{ $t('history.wallet') }}</div>
            <div class="direction cell">{{ $t('history.direction') }}</div>
            <div class="address cell">{{ $t('history.address') }}</div>
            <div class="amount cell">{{ $t('history.amount') }}</div>
            <div class="currency cell">{{ $t('history.network') }}</div>
            <div class="date cell">
                {{ $t('history.date') }}
                <g-symbol name="arrows"
                          width="14"
                          height="14"
                          v-on:click="transaction.resort('created_at')"/>
            </div>
            <div class="status cell">{{ $t('history.status') }}</div>
            <div class="confirmations cell">{{ $t('history.confirmation') }}</div>
            <div class="actions cell">{{ $t('history.actions') }}</div>
        </div>
        <div v-if="!transaction.isLoaded" class="hint-text-wrapper">
            <g-preloader is-dark class="g-preloader"/>
        </div>
        <div v-else-if="isShowHint" class="hint-text-wrapper">
            <div class="hint-text">No data found for specified dates</div>
        </div>
        <template v-else>
            <div v-for="(item, key) in transaction.state" v-bind:key="item.id" class="item-row">
                <template v-if="item.operation">
                    <div class="wallet cell" data-label="Wallet">
                        <div class="wallet-wrapper">
                            <g-symbol-currency :name="item.coin.symbol.toLowerCase()" width="42" height="42"/>
                            <div class="content">{{ item.wallet && item.wallet.name }}</div>
                        </div>
                    </div>
                    <div class="direction cell" data-label="Direction">
                        <div class="content">{{ item.type }}</div>
                    </div>
                    <div class="address cell" data-label="address">
                        <div v-if="item.operation.address" class="address-wrap" :style="`max-width: calc(${item.operation.address.length + 2}ch + 20px`">
                            <g-copy class="content" v-bind:text="item.operation.address"/>
                        </div>
                        <div v-else class="address-wrap"></div>
                    </div>
                    <div class="amount cell" data-label="Amount">
                        <div class="content">{{ Number(item.operation.amount) }} {{ item.coin.symbol }}</div>
                    </div>
                    <div class="currency cell" data-label="Currency">
                        <div class="content">{{ item.coin.name }}</div>
                    </div>
                    <div class="date cell" data-label="Date">
                        <div class="content">{{ item.created_at }}</div>
                    </div>
                    <div class="status cell" data-label="Status">
                        <g-badge :fill="getBadgeColor(item.operation.state)" size="small">
                            {{ item.operation.state }}
                        </g-badge>
                    </div>
                    <div class="confirmations cell" data-label="Confirmations">
                        {{ item.confirmations }}
                    </div>
                    <div class="actions cell" data-label="Actions">
                        <g-button :size="'medium'" @click="showDetails(key)" :class="{ 'primary-fill': key === +route.params.transactionId}">
                            {{ $t('button.details') }}
                        </g-button>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { useHistoryActions } from '@history';
import { useShipType } from '@/Ship';
import { computed } from 'vue';
import GBadge from '../../../../Ship/components/global/customs/GBadge.vue';

const { getTransactionResource } = useHistoryActions();

export default {
    name: 'History',
    components: {GBadge},
    setup() {
        const router = useRouter();
        const route = useRoute();

        const transaction = getTransactionResource();
        transaction.dropPerPage();

        const status = useShipType('status');

        const showDetails = (idx) => {
            router.push({
                name: 'Transaction',
                params: { ...route.params, transactionId: idx }
            });

            transaction.index = idx;
        };

        function getBadgeColor(value) {
            switch(value) {
            case 'Sent':
            case 'Forward':
            case 'Confirmed':
                return status.success;
            case 'Error':
            case 'Canceled':
                return status.danger;
            default:
                return status.warning;
            }
        }

        const isShowHint = computed(() => {
            return transaction.state && !transaction.state.length && transaction.isLoaded;
        });

        return {
            route,
            isShowHint,
            transaction,
            showDetails,
            getBadgeColor,
        };
    }
};
</script>

<style lang="scss" scoped>
.history-wrapper {
    padding: 0 40px;
}

:deep(.g-preloader) {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.hint-text-wrapper {
    position: relative;
    width: 100%;
    height: 50vh;

    .hint-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.titles > .cell {
    font-weight: 600;
    font-size: var(--text-md, $text-md);
    line-height: 140%;
    color: var(--disabled-color, $disabled-color);

    .g-symbol {
        margin-left: 7px;
        cursor: pointer;

        &:not(.active) {
            fill: var(--disabled-color, $disabled-color);
        }

        &.active {
            fill: var(--primary, $primary);
        }
    }
}

.item-row {
    .cell {
        color: var(--main-text-color, $main-text-color);
        font-size: var(--text-md, $text-md);
        line-height: 140%;
        font-weight: 400;

        &.wallet {
            grid-area: wlt;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            .wallet-wrapper {
                display: flex;
                align-items: center;
            }
        }

        &.direction {
            grid-area: dir;
        }

        &.date {
            grid-area: dte;
        }

        &.currency {
            grid-area: crr;
        }

        &.address {
            grid-area: adr;
        }

        &.amount {
            grid-area: amt;
            font-weight: 600;
            font-size: 16px;
        }

        &.status {
            grid-area: sts;
        }

        &.confirmations {
            grid-area: cfm;
        }

        &.actions {
            grid-area: act;

            .g-button {
                &.primary-fill {
                    font-weight: 400;
                }
            }
        }
    }
}

@media (min-width: 1490px) {
    .history-wrapper {
        width: 100%;
        display: table;
        table-layout: auto;

        & > div {
            display: table-row;

            & > .cell {
                display: table-cell;
                white-space: nowrap;
                text-align: center;
                padding: 20px 0.2vw;
                border-bottom: 1px solid var(--separator-color, $separator-color);
                vertical-align: middle;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.wallet {
                    text-align: left;
                    white-space: normal;

                    & > div {
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }

                    .g-symbol-currency {
                        flex-shrink: 0;
                        margin-right: 16px;
                    }
                }

                &.address {
                    text-align: left;
                }

                &.amount {
                    text-align: right;
                }
            }
        }
    }

    .address-wrap {
        display: table;
        table-layout: fixed;
        width: 100%;
        font-size: var(--text-xs, $text-xs);
    }
}

@media (min-width: 851px) and (max-width: 1490px) {
    .history-wrapper > div {
        display: inline-grid;
        grid-template-areas:
            'wlt sts dte adr adr'
            'crr cfm dir amt act';
        grid-template-columns:
            minmax(200px, 380px)
            minmax(100px, 140px)
            minmax(100px, 180px)
            minmax(180px, 380px)
            100px;
    }
}

@media (max-width: 1489px) {
    .titles {
        display: none !important;
        background-color: red;
    }

    .item-row {
        border-bottom: 1px solid var(--separator-color, $separator-color);
        padding: 20px 0;
    }

    .cell {
        min-height: 100%;

        &::before {
            display: block;
            content: attr(data-label);
            font-size: var(--text-xs, $text-xs);
            color: var(--disabled-color, $disabled-color);
        }

        &.wallet {
            .g-symbol-currency {
                flex-shrink: 0;
                margin-right: 16px;
            }
        }
    }
}

@media (max-width: 1300px) and (min-width: 1101px) {
    .history-wrapper {
        padding: 0 25px;
    }
}

@media screen and (max-width: 1100px) {
    .history-wrapper {
        padding: 0 20px;
    }
}

@media (max-width: 850px) {
    .item-row {
        display: grid;
        grid-template-areas:
            'wlt wlt'
            'dir dte'
            'adr adr'
            'amt amt'
            'sts cfm'
            'crr act';
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin: 0 0 20px;
        border: 1px solid var(--separator-color, $separator-color);
        border-radius: var(--rounding-lg, $rounding-lg);
        padding: 20px;
    }
}

@media (max-width: 580px) {
    .history-wrapper {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 359px) {
    .history-wrapper {
        padding: 0 10px;
    }

    .item-row {
        padding: 10px;
    }
}

</style>
