export default function (requireActions) {
    return {
        list() {
            let actionList = {};
            requireActions.keys().forEach(fileName => {
                const actionName = fileName.split('/').pop().replace(/\.\w+$/, '');
                actionList[actionName] = requireActions(fileName).default;
            });

            return actionList;
        },
        action(ask) {
            const action = requireActions.keys().find(fileName => {
                return new RegExp(`${ask}.js`).test(fileName);
            });

            return requireActions(action).default;
        }
    };
}