<template>
    <div class="wallet wallet-wrapper">
        <!-- CARD -->
        <div class="card">
            <wallet-card v-bind:wallet-name="wallet.name"
                         v-bind:network="wallet.coin.network"
                         v-bind:symbol="wallet.coin.symbol"
                         v-bind:isInternal="wallet.type === 'internal'"
                         v-bind:balanceValue="wallet.balance"/>
        </div>
        <!-- /CARD -->

        <!-- TITLES -->
        <div class="options">
            <div class="address">
                <div class="title">
                    <g-symbol class="icon" name="send" width="22" height="22"/>
                    {{ $t('wallet.address') }}
                </div>
                <g-copy v-bind:text="wallet.address"/>
            </div>
            <div class="created-at">
                <div class="title">
                    <g-symbol name="calendar" class="icon" width="22" height="22"/>
                    {{ $t('wallet.created_at') }}
                </div>
                {{ wallet.created_at }}
            </div>
            <div class="type">
                <div class="title">
                    <g-symbol name="card" class="icon" width="22" height="22"/>
                    {{ $t('wallet.type') }}
                </div>
                {{ $t(`wallet.${wallet.type}`) }}
            </div>
        </div>
        <!-- /TITLES -->

        <!-- TRANSACTIONS -->
        <div class="wallet-client">
            <g-select v-model="walletClient"
                      v-model:index="walletClientIndex"
                      v-bind:label="$t('label.client')"
                      v-on:change="selectClient(wallet.id)"
                      :options="clientList"
                      option-text="name"
                      option-value="id"/>
        </div>
        <!-- /TRANSACTIONS -->

        <!-- ACTIONS -->
        <div class="actions">
            <g-button size="large" icon-color="primary" square
                      v-on:click="openSendPopUp(wallet.id)">
                <g-symbol name="card-send" width="22" height="22"/>
            </g-button>
            <g-button size="large" icon-color="success" square
                      v-on:click="PopUp.open(require('@wallets/components/parts/Receive')).setCaption($t('label.receive')).setPayload({ address: wallet.address })">
                <g-symbol name="card-receive" width="22" height="22"/>
            </g-button>
            <g-button size="large" icon-color="danger"
                      v-on:click="PopUp.open(require('@wallets/components/parts/RemoveWallet')).setCaption($t('popupTitle.alert')).setPayload({ wallet: wallet })" square>
                <g-symbol name="delete" width="22" height="22"/>
            </g-button>
        </div>
        <!-- /ACTIONS -->
    </div>
</template>

<script>

import { computed, ref } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useWalletComponent, useWalletResource } from '@wallets';
import { useProfileResource } from '@profile';

export default {
    name: 'SubWallet',
    props: {
        wallet: {
            type: Object,
        },
        idx: {
            type: Number,
        }
    },
    components: {
        WalletCard: useWalletComponent('WalletCard')
    },

    setup(props) {
        const getWalletName = computed(() => {
            const wallet = props.wallet;
            return wallet && wallet.walletName;
        });

        const getWalletCurrencySign = computed(() => {
            const wallet = props.wallet;
            return wallet && wallet.currency;
        });

        const getWalletBalance = computed(() => {
            const wallet = props.wallet;
            return wallet && wallet.amount;
        });

        const getLastTransactions = computed(() => {
            const wallet = props.wallet;
            return wallet && wallet.lastTransactions;
        });

        const walletResource = useWalletResource('Wallet');

        function openSendPopUp(idx) {
            const item = walletResource.state.find(item => item.id === idx);
            walletResource.index = walletResource.state.indexOf(item);
            PopUp.open(require('@wallets/components/parts/SendTransaction')).setCaption(i18n.global.getLocaleMessage(i18n.global.locale.value).popupTitle.send);
        }

        const client = useProfileResource('Client');
        const walletClient = ref(null);

        const walletClientIndex = computed(() => {
            const clientId = props.wallet.oauth_client_id;
            const item = client.state.find(item => item.id === clientId);

            return client.state.indexOf(item) + 1;
        });



        const clientList = computed(() => {
            return [{ id: null, service_url: 'None', secret: 'None', name: 'None' }, ...client.state];
        });

        const selectClient = (id) => {
            walletResource.save(id, {
                oauth_client_id: walletClient.value
            });
        };


        return {
            PopUp,
            client,
            walletClient,
            clientList,
            walletResource,
            walletClientIndex,
            getWalletName,
            getWalletCurrencySign,
            getLastTransactions,
            getWalletBalance,
            openSendPopUp,
            selectClient
        };
    }
};
</script>

<style lang="scss" scoped>
.wallet-wrapper {
    display: grid;
    grid-gap: 20px;

    & > .card {
        grid-area: crd;
    }

    & > .options {
        grid-area: opt;
        display: flex;
        flex-wrap: wrap;
    }

    & > .wallet-client {
        grid-area: clt;
        max-width: 340px;
    }

    & > .actions {
        grid-area: act;
        text-align: right;

        .g-button {
            margin: 5px;
        }
    }
}

.title {
    gap: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: var(--title-text-color, $title-text-color);

    & > .g-symbol {
        fill: var(--title-text-color, $title-text-color);
    }
}

@media (min-width: 1545px) {
    .wallet-wrapper {
        grid-template:
            'crd opt act' 58px
            'crd clt act' 140px / 340px auto minmax(min-content, 140px);
    }

    .options {
        justify-content: flex-start;

        & > div {
            margin-right: 18px;
        }
    }
}

@media (max-width: 1544px) {
    .options {
        align-content: flex-start;

        & > div {
            padding: 10px 0;
            flex-basis: 100%;
        }
    }

    @media (min-width: 845px) {
        .wallet-wrapper {
            grid-template:
                'crd opt act' 210px
                'clt clt clt' auto / 340px auto minmax(min-content, 140px);
        }
    }
}

@media (max-width: 844px) {
    .actions {
        display: flex;
        justify-content: space-evenly;
    }

    @media (min-width: 665px) {
        .wallet-wrapper {
            grid-template:
                'crd opt' 200px
                'clt act' 80px / 310px auto;
        }

        .actions {
            justify-content: flex-end;
        }
    }
}

@media (max-width: 664px) {
    .options {
        align-items: center;
    }

    .actions {
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
    }

    .wallet-wrapper {
        grid-template:
            'crd' 204px
            'clt' auto
            'act' auto
            'opt' auto / auto;

        & > .card {
            display: flex;
            justify-content: center;
        }
    }

    .wallet-wrapper > .wallet-client {
        margin: 0 auto;
        width: 100%;
    }
}
</style>
