export default [
    {
        name: 'History',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/history`,
        component: () => import('./View'),
        children: [
            {
                name: 'Transaction',
                path: ':transactionId',
                component: () => import('./Transaction'),
            },
        ],
    },
];
