<template>
    <div class="g-select" :class="[size, { 'disabled':  disabled}, {active: isActive }, {focus: show }]">
        <div :class="{ 'show-datalist': show }">
            <label>{{ label }}</label>
            <input v-bind="$attrs"
                   v-on:focus="showList"
                   v-on:blur="hideList"
                   ref="selectInput"
                   readonly>
            <span class="input-btn" @click="toggleList">
                <g-symbol name="arrow-down" width="24" height="24"/>
            </span>
            <div ref="datalist"
                 class="datalist"
                 v-bind:class="{ 'to-top': toTop }">
                <label v-for="(option, key) in options"
                       v-bind:key="key"
                       v-bind:class="{active: key === selectedIndex}">
                    <input type="radio"
                           v-model="selectedIndex"
                           v-bind:value="key"
                           @change="onValueChange(selectedIndex)"
                           hidden>
                    {{ option[optionText] }}
                </label>
            </div>
        </div>
        <div v-if="hint" class="hint-text">{{ hint }}</div>
    </div>
</template>

<script>

import {
    ref,
    onMounted, computed
} from 'vue';

export default {
    name: 'GSelect',
    props: {
        modelValue: {},
        index: {
            type: Number,
            default: 0
        },
        options: {
            type: Object,
            default: () => {}
        },
        optionText: {
            Type: [String, Object],
        },
        optionValue: {
            Type: [String, Object],
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'large',
        },
        hint: { type: String },
        label: { type: String },
    },
    emits: ['update:modelValue', 'update:index'],

    setup(props, { emit }) {

        const zIndex = ref(1);
        const show = ref(false);
        const toTop = ref(false);
        const datalist = ref(null);
        const selectedIndex = ref(null);
        const selectInput = ref(null);

        onMounted(() => {
            selectedIndex.value = props.index;
            processing();
        });

        const showList = () => {
            const { y } = datalist.value.getBoundingClientRect();
            toTop.value = (y + 300) > screen.availHeight;
            show.value = true;
            zIndex.value++;
        };
        const hideList = () => {
            setTimeout(() => {
                show.value = false;
            }, 200);
            setTimeout(() => {
                zIndex.value--;
            }, 400);
        };
        const toggleList = () => {
            if (!show.value) selectInput.value.focus();
        };

        const onValueChange = () => {
            processing();
        };

        const processing = () => {
            const modelValue = props.options
                && props.options[selectedIndex.value]
                && props.options[selectedIndex.value][props.optionValue];

            selectInput.value.value = props.options[selectedIndex.value]
                && props.options[selectedIndex.value][props.optionText] || '';

            emit('update:index', selectedIndex.value);
            emit('update:modelValue', modelValue);
        };

        const isActive = computed(() => {
            return Number.isInteger(selectedIndex.value);
        });

        return {
            zIndex,
            show,
            toTop,
            datalist,
            selectedIndex,
            selectInput,
            showList,
            hideList,
            toggleList,
            onValueChange,
            processing,
            isActive
        };
    }
};
</script>

<style lang="scss" scoped>
.g-select {
    z-index: v-bind(zIndex);
    padding: 0;
    margin: 2px 0;
    position: relative;

    & > div:first-child {
        z-index: 3;
        position: relative;
        fill: var(--label-color, $label-color);
        display: flex;
        align-items: center;
        border-radius: var(--select-rounding, $select-rounding);
        background-color: var(--select-bg, $select-bg);
        border: 1px solid var(--select-border, $select-border);
        transition: border-color 0.2s;

        & > label {
            position: absolute;
            height: 20px;
            font-size: var(--label-font-size, $label-font-size);
            color: var(--label-color, $label-color);
            line-height: 20px;
            letter-spacing: normal;
            padding: 0 20px;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            pointer-events: none;
            transition: all 0.2s;
        }

        & > input {
            width: 100%;
            border: none;
            flex-grow: 1;
            color: var(--main-text-color, $main-text-color);
            font-size: var(--text-md, $text-md);
            font-family: var(--font-primary, $font-primary);
            font-weight: 500;
            padding: 18px 3px 0 20px;
            cursor: pointer;
            box-sizing: border-box;
            background-color: transparent;
            height: var(--input-height-large, $input-height-large);
            border-radius: var(--input-rounding, $input-rounding);

            &::placeholder {
                color: var(--label-color, $label-color);
                opacity: 1;
            }

            &:focus {
                outline: none;
            }
        }

        .input-btn {
            margin-right: 14px;
            font-size: 0;
            height: auto;
            transition: transform 0.2s;
        }

        &:hover {
            cursor: pointer;
        }

        &:not(.show-datalist) {
            & > .datalist {
                max-height: 0;
                border-width: 0;
                opacity: 0;

                & > output {
                    pointer-events: none;
                }
            }
        }

        &.show-datalist {
            & > .datalist {
                max-height: 300px;
            }

            .input-btn {
                transform: rotate(180deg);
            }
        }
    }

    &.error {
        color: var(--danger, $danger);

        input {
            background: var(--input-active-bg, $input-active-bg);
        }

        & > div:first-child {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--danger, $danger);
            box-shadow: 0 0 0 1px var(--danger, $danger);
        }

        & > div > label {
            color: var(--danger, $danger);
        }

        .hint-text {
            color: var(--danger, $danger);
        }
    }

    &.focus {
        & > div:first-child {
            border-color: var(--secondary, $secondary);
            background: var(--input-active-bg, $input-active-bg);
            box-shadow: 0 0 0 1px var(--secondary, $secondary);

            & > label {
                color: var(--label-color, $label-color);
            }

            input {
                background: var(--input-active-bg, $input-active-bg);
            }
        }
    }

    &.active > div {
        & > label {
            top: 19px;
            font-size: var(--label-focus-font-size, $label-focus-font-size);
        }
    }

    &.active.focus {
        & > div > label {
            color: var(--label-color, $label-color);
        }
    }

    .datalist {
        left: -1px;
        width: calc(100% + 2px);
        position: absolute;
        box-sizing: border-box;
        background-color: var(--dropdown-bg, $dropdown-bg);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        border: 1px solid var(--dropdown-list-border, $dropdown-list-border);
        transition: all 0.03s, border-width;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 1ex;
            background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
        }

        &.to-top {
            bottom: 100%;
            margin-bottom: 8px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 5px;
        }

        label {
            display: block;
            cursor: pointer;
            padding: 14px 20px;
            font-weight: 400;
            font-size: var(--text-md, $text-md);
            line-height: 120%;
            color: var(--main-text-color, $main-text-color);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
                border-bottom: none;
            }

            &.active {
                pointer-events: none;
                color: var(--secondary-hover, $secondary-hover);
                font-weight: 700;
            }

            &:not(.active):hover {
                color: var(--secondary-hover, $secondary-hover);
            }
        }
    }

    &.large {
        & > div {
            & > input {
                height: var(--input-height-large, $input-height-large);
            }
        }
    }

    &.medium {
        & > div {
            & > input {
                height: var(--input-height-medium, $input-height-medium);
            }
        }

        &.active > div {
            label {
                top: 14px;
            }
        }

        &:deep(svg) {
            width: 22px;
            height: 22px;
        }
    }

    &.small {
        & > div {
            & > input {
                height: var(--input-height-small, $input-height-small);
                font-size: var(--input-font-size-sm, $input-font-size-sm);
                padding: 0 15px 0 12px;
            }

            & > label {
                padding: 0 15px 0 12px;
                font-size: var(--input-font-size-sm, $input-font-size-sm);
            }
        }

        &.active > div {
            & > label {
                display: none;
            }
        }

        .datalist {
            & > label {
                font-size: var(--input-font-size-sm, $input-font-size-sm);
                padding: 6px 15px 6px 12px;
            }
        }

        &:deep(svg) {
            width: 20px;
            height: 20px;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;

        svg {
            fill: var(--disabled, $disabled);
        }

        & > div > input {
            pointer-events: none;
            color: var(--disabled, $disabled);
            cursor: default;

            &::placeholder {
                color: var(--disabled, $disabled);
                opacity: 1;
            }
        }
    }
}

.hint-text {
    font-size: var(--text-xs, $text-xs);
    line-height: 140%;
    font-weight: 400;
    color: var(--input-hint-color, $input-hint-color);
    margin-top: 6px;
}
</style>
