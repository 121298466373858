import componentLoader from '@/Ship/core/ComponentLoader';
import actionLoader from '@/Ship/core/ActionLoader';
//import taskLoader from "@/Ship/core/taskLoader";
import resourceLoader from '@/Ship/core/ResourceLoader';

export function useProfileAction(ask) {
    const requireActions = require.context('./actions', true, /[\w-_]+\.js$/);
    return actionLoader(requireActions).action(ask);
}

export function useProfileActions() {
    const requireActions = require.context('./actions', true, /[\w-_]+\.js$/);
    return actionLoader(requireActions).list();
}

// export function useProfileTask(ask) {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return taskLoader(requireComponents).task(ask);
// }

// export function useProfileTasks() {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return taskLoader(requireComponents).list();
// }

export function useProfileResource(ask) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).resource(ask);
}

export function useProfileResources() {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).list();
}

export function useProfileComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useProfileComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export default require('./View').default;
