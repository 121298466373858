import {reactive, ref} from 'vue';
import { ApiClient, i18n, STACK } from '@/Ship';
import { useAuthenticationAction } from '@auth';

import ValidRules from '@/Ship/core/ValidRules';
import { useProfileResource } from '@profile';

const validRules = new ValidRules();

export default class Profile {

    static #instance = null;
    endpoint = '/profile';
    rules = {
        email: ['email', 'require'],
        first_name: 'require | minLength:2',
        last_name: 'require | minLength:2',
        //service_url: ['urlCheck'],
    };
    validate = reactive({});
    model = reactive({});
    //entity = reactive({});
    entity = {};
    loaded = ref(false);

    constructor() {
        if (!Profile.#instance) {
            Profile.#instance = new Proxy(this.model, {
                get: (target, key) => {
                    return key in this.model
                        ? this.model[key]
                        : this[key] || undefined;
                },
                set: (target, key, value) => {
                    // Todo add proxi object
                    this.entity[key] = value;
                    this.model[key] = value;
                    this.initValidation(key, value);
                    return true;
                }
            });
        }

        return Profile.#instance;
    }

    static getInstance() {
        return new Profile();
    }

    get isLoaded() {
        return this.loaded.value;
    }

    load() {
        return STACK.push(() => ApiClient.get(this.endpoint, { params: {} })).then(response => {
            const payload = response.data;

            Object.assign(this.model, payload);
            this.loaded.value = true;
            Object.assign(this.validate, this.rules);

            //this.validate.api_clients = [];

            // payload.api_clients.forEach(item => {
            //     this.validate.api_clients.push({ id: item.id, service_url: this.rules.service_url });
            // });

            for (let key in this.validate) {
                this.validate[key] = true;

                // if (key === 'api_clients') {
                //     this.validate[key].forEach(item => item.service_url = true);
                // } else {
                //     this.validate[key] = true;
                // }
            }

            const client = useProfileResource('Client');
            client.collection.value = payload.api_clients;
        });

    }

    save() {
        if (this.isValidationPassed()) {
            STACK.push(() => ApiClient.patch(`${this.endpoint}/${this.model.id}`, this.entity));
        }
    }

    delete() {
        STACK.push(() => ApiClient.delete(`${this.endpoint}/${this.model.id}`).then(() => {
            const signOut = useAuthenticationAction('signOut');
            signOut();
        }));
    }

    clearEntity() {
        Object.keys(this.entity).forEach(key => delete this.entity[key]);
    }

    // initNestedValidation(key, collection) {
    //     if (this.rules[key] && collection) {
    //         collection.forEach((item, i) => {
    //             this.validate.api_clients[i].service_url = this.errors(this.rules['service_url'], item.service_url);
    //         });
    //     }
    // }

    initValidation(key, value) {
        if (this.rules[key]) {
            this.validate[key] = this.errors(this.rules[key], value);
        }
    }

    errors(rules, value) {
        let errorList = [];
        let processedRules = typeof rules === 'object' ? rules : [];

        if (typeof rules === 'string') {
            const separatedRules = rules.split('|');

            separatedRules.forEach(rule => {
                rule = rule.replace(/\s/g, '');
                let isRuleHasParam = rule.indexOf(':') > -1 ? rule.split(':') : rule;

                processedRules.push(isRuleHasParam);
            });
        }

        processedRules.forEach((rule, index) => {
            if (typeof rule === 'object') {
                let param = rule[1];

                validRules[rule[0]](value, param)
                    ? errorList.splice(index, 1)
                    : errorList = [i18n.global.t(`validator.${rule[0]}`)];
            }

            if (typeof rule === 'string') {
                validRules[rule](value)
                    ? errorList.splice(index, 1)
                    : errorList = [i18n.global.t(`validator.${rule}`)];
            }
        });

        return errorList;
    }

    isValidationPassed = () => {
        let isValid = true;
        for (let key in this.validate) {
            if (Array.isArray(this.validate[key])) {
                for (let item of this.validate[key]) {
                    isValid = (item.service_url === true || (item.service_url && !item.service_url.length));
                    if (!isValid) break;
                }
            } else {
                isValid = (this.validate[key] === true || !this.validate[key].length);
            }

            if (!isValid) break;
        }

        return isValid;
    };
}
