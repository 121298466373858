<template>
    <cover>
        <component v-bind:is="$route.name"/>
    </cover>
</template>

<script>

import { useHomeComponent } from '@home';
import { useAuthenticationComponents } from '@/Containers/Authentication/Authentication';

export default {
    name: 'Authentication',
    components: {
        Cover: useHomeComponent('Cover'),
        ...useAuthenticationComponents(),
    },
};
</script>

<style lang="scss" scoped>
</style>
