<template>
    <div class="infobar">
        <router-link :to="{ name: 'History', params: router.currentRoute.value.params }" class="button-back">
            <g-symbol name="close" width="24" height="24"/>
        </router-link>
        {{ $t('history.payment_details') }}
    </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
    name: 'Infobar',
    setup(){
        return {
            router: useRouter()
        };
    }

};
</script>

<style lang="scss" scoped>
.infobar {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--separator-color, $separator-color);
    border-top: 1px solid var(--separator-color, $separator-color);
    padding: 24px 34px;
    font-size: var(--text-md, $text-md);
    font-weight: 600;
    line-height: 140%;

    .button-back {
        margin-right: 22px;
        display: block;
        height: 24px;
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--primary, $primary);
        }
    }
}

@media screen and (max-width: 440px) {
    .infobar {
        padding: 15px 20px;
    }
}
</style>
