export default function (requireTypes) {

    return {
        list() {
            let typeList = {};
            requireTypes.keys().forEach(fileName => {
                const typeName = fileName.split('/').pop().replace(/\.\w+$/, '');
                typeList[typeName] = requireTypes(fileName).default;
            });

            return typeList;
        },
        type(ask) {
            const type = requireTypes.keys().find(fileName => {

                return new RegExp(`${ask}.js`).test(fileName);
            });

            return requireTypes(type).default;
        }
    };
}