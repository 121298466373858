export default function (requireActions) {
    return {
        list() {
            let tasksList = {};
            requireActions.keys().forEach(fileName => {
                const actionName = fileName.split('/').pop().replace(/\.\w+$/, '');
                tasksList[actionName] = requireActions(fileName).default;
            });

            return tasksList;
        },
        task(ask) {
            const task = requireActions.keys().find(fileName => {
                return new RegExp(`${ask}.js`).test(fileName);
            });

            return requireActions(task).default;
        }
    };
}