import componentLoader from '@/Ship/core/ComponentLoader';


export function useHomeComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useHomeComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export default require('./View').default;