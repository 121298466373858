import { reactive } from 'vue';

const STATE = reactive({
    LOADING: false,
    IS_LOADING: false,
    IS_PROCESSING: false,
    IS_LOGIN: false,
    IS_VISIBLE: true,
    IS_DARK_THEME: false,
    AUTO_SAVE: true,
    IS_SHOW_LEFTBAR: false,
    IS_SHOW_PARTBAR: false,
    historyState: {
        filterDateRange: null
    }
});

export default STATE;
