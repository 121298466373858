<template>
    <svg class="g-symbol-currency" v-bind="$attrs">
        <use v-bind:xlink:href="require(`@assets/img/currency-sprite.svg`) + `#${name}`"/>
    </svg>
</template>

<script>
export default {
    name: 'GSymbolCurrency',
    props: ['name'],
};
</script>

<style scoped>
svg {
    vertical-align: middle;
}
</style>
