<template>
    <div class="bar">
        <br/>
        <h2>ROOT</h2>
        <br/>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';

export default {
    name: 'EnvSidebar',

    setup() {
        return {
            route: useRoute(),
        };
    },
};
</script>

<style lang="scss" scoped>
.bar {
    width: 100%;
    height: 100%;
    padding: 5px 20px;
    box-sizing: border-box;
    color: var(--sidebar-color, $sidebar-color);
    background-color: var(--sidebar-bg, $sidebar-bg);
}
</style>
