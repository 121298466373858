<template>
    <ul class="g-pagination">
        <li>
            <g-button
                class="button-prev"
                square
                :size="size"
                :disabled="isPageActive(1)"
                @click="onClickPrevPage"
            >
                <g-symbol name="arrow-down" width="24" height="24"/>
            </g-button>
        </li>
        <li v-for="item in totalPages" :key="item.id">
            <g-button
                square
                :size="size"
                :class="{ 'primary-fill': isPageActive(item) }"
                @click="onClickPage(item)"
            >{{ item }}
            </g-button>
        </li>
        <li>
            <g-button
                class="button-next"
                square
                :size="size"
                :disabled="isPageActive(totalPages)"
                @click="onClickNextPage"
            >
                <g-symbol name="arrow-down" width="24" height="24"/>
            </g-button>
        </li>
    </ul>
</template>

<script>
import { ref, toRefs } from 'vue';

export default {
    name: 'GPagination',
    components: {},
    props: {
        totalPages: {
            type: Number,
            required: true
        },
        size: {
            default: 'medium'
        },
        currentPage: {
            type: Number,
            default: 1
        }
    },
    setup(props) {
        const { currentPage } = toRefs(props);
        let propCurrentPage = ref(currentPage.value);

        const onClickPage = (item) => {
            propCurrentPage.value = item;
        };

        const isPageActive = (page) => {
            return propCurrentPage.value === page;
        };

        const onClickNextPage = () => {
            propCurrentPage.value++;
        };

        const onClickPrevPage = () => {
            propCurrentPage.value--;
        };

        return {
            onClickPage,
            isPageActive,
            propCurrentPage,
            onClickNextPage,
            onClickPrevPage
        };
    }


};
</script>

<style lang="scss" scoped>
.g-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        padding: 0 4px;
    }
}

.button-prev {
    &:deep(svg) {
        transform: rotate(90deg);
    }
}

.button-next {
    &:deep(svg) {
        transform: rotate(-90deg);
    }
}
</style>