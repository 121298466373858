<template>
    <div class="wallets environment">

        <div class="actions">
            <g-button class="create-btn"
                      size="large"
                      fill="primary"
                      v-on:click="PopUp.open(require('@wallets/components/parts/CreateWallet')).setCaption($t('popupTitle.create'))">
                <g-symbol name="plus" width="24" height="24"/>
                {{ $t('button.new_wallet') }}
            </g-button>
            <g-select v-model="selectedCoinId"
                      v-bind:label="$t('label.currency')"
                      :options="currencyList"
                      option-text="symbol"
                      option-value="id"
                      default-value="all"/>
        </div>
        <g-frame v-if="!wallet.isLoaded">
            <g-preloader is-dark/>
        </g-frame>
        <g-frame v-else>
            <wallet v-for="(wallet, key) in wallets" v-bind:key="key" v-bind:wallet="wallet"/>
        </g-frame>
    </div>
</template>

<script>

import { STATE, PopUpManager as PopUp } from '@/Ship';
import { ref, onMounted, computed } from 'vue';
import { useWalletComponent, useWalletActions } from '@wallets';

const { getWalletResource } = useWalletActions();

export default {
    name: 'Environment',

    components: {
        Wallet: useWalletComponent('Wallet'),
    },

    setup: function () {
        onMounted(() => {
            STATE.IS_SHOW_LEFTBAR = false;
        });
        const wallet = getWalletResource();

        const selectedCoinId = ref(null);

        const currencyList = computed(() => {
            const list = wallet.state.map(item => {
                return item.coin;
            });

            const cointList = [];
            const cointListId = [];
            for (let i = 0; i < list.length; i++) {
                if (!cointListId.includes(list[i].id)) {
                    cointListId.push(list[i].id);
                    cointList.push(list[i]);
                }
            }
            return [{ id: null, name: 'All', symbol: 'All' }, ...cointList];
        });

        const wallets = computed(() => {
            return selectedCoinId.value
                ? wallet.state.filter(item => item.coin_id === selectedCoinId.value)
                : wallet.state;
        });

        return {
            wallet,
            PopUp,
            currencyList,
            selectedCoinId,
            wallets,
        };
    }
};
</script>

<style scoped lang="scss">
.wallets {
    gap: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .frame-wrapper {
        height: calc(100% - 49px);
    }

    & > .actions {
        gap: 12px;
        display: flex;
        justify-content: space-between;

        .create-btn {
            min-width: 317px;
        }
    }
}

.wallet {
    padding: 48px 0;
    box-sizing: content-box;
    border-bottom: 1px solid var(--separator-color, $separator-color);

    &:last-child {
        border-bottom: none;
    }
}

:deep(.g-preloader) {
    top: 50%;
}

@media (min-width: 925px) {
    .wallets:deep(.g-frame),
    .wallets > .actions {
        padding: 0 40px;
    }
}

@media (max-width: 924px) {
    .wallets:deep(.g-frame),
    .wallets > .actions {
        padding: 0 20px;
    }
}

@media (max-width: 684px) {
    .wallets > .actions {
        & > * {
            width: 50%;
        }
    }

    .wallets .actions .create-btn {
        min-width: 0;
    }
}

@media (max-width: 520px) {
    .wallets > .actions {
        flex-wrap: wrap;

        & > * {
            width: 100%;
        }
    }
}

@media (max-width: 360px) {
    .wallets:deep(.g-frame),
    .wallets > .actions {
        padding: 0 10px;
    }
}
</style>
