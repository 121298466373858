<script>
import { useDashboardComponent } from '@dashboard';
import { useEnvComponent } from '@environment';

export default {
    name: 'Dashboard',
    extends: useEnvComponent('Layout'),
    components: {
        Environment: useDashboardComponent('Environment'),
    },

    setup() {

        return {
            title: 'dashboard'
        };
    },
};
</script>
