// let selectStart = 0;
let inputValue = '';

export default function phoneMask(event, code) {
    inputValue = event.target.value;

    const keyCode = event.keyCode || null;

    const matrix = `${code} (___) ___-__-__`;
    const def = matrix.replace(/\D/g, '');
    let i = 0;
    let val = inputValue.replace(/\D/g, '');
    let new_value = matrix.replace(/[_\d]/g, a => {
        return i < val.length
            ? val.charAt(i++) || def.charAt(i)
            : a;
    });
    i = new_value.indexOf('_');
    if (i !== -1) {
        i < 5 && (i = 3);
        new_value = new_value.slice(0, i);
    }
    let reg = matrix.substr(0, inputValue.length).replace(/_+/g, function (a) {
        return '\\d{1,' + a.length + '}';
    }).replace(/[+()]/g, '\\$&');

    if (!(new RegExp('^' + reg + '$')).test(inputValue) || inputValue.length < 5 || keyCode > 47 && keyCode < 58) {
        inputValue = new_value;
    }
    if (event.type === 'blur' && inputValue.length < 5) {
        inputValue = '';
    }

    return inputValue;

}

