<template>
    <div class="transactions">
        <div class="title">
            <g-symbol class="icon" name="documents" width="22" height="22"/>
            {{ $t('wallet.last_transaction') }}
        </div>

        <div class="transaction" v-for="(transaction, key) in list" :key="key">
            <div class="address cell" v-bind:data-label="$t('history.address')">
                <g-copy v-bind:text="transaction.address"/>
            </div>
            <div class="date cell" v-bind:data-label="$t('history.date')">
                <g-symbol name="calendar" class="icon" width="18" height="18"/>
                {{ '2022-10-26 16:42:55' }}
            </div>
            <div class="amount cell" v-bind:data-label="$t('history.amount')">
                <b>{{ transaction.amount }}</b>
                {{ transaction.currency }}
            </div>
            <div class="status cell" v-bind:data-label="$t('history.status')">
                <g-badge :fill="getBadgeColor(transaction.status)" size="small">
                    {{ transactionStatus(transaction.status) }}
                </g-badge>
            </div>
            <div class="direction cell" v-bind:data-label="$t('history.direction')">{{ transaction.direction }}</div>
        </div>
        <!--<div class="column-content">{{ $t('wallet.empty_transaction') }}</div>-->
    </div>
</template>

<script>

import { i18n } from '@/Ship';

export default {
    name: 'Transactions',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },

    setup() {

        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
        };

        const getBadgeColor = (value) => {
            if (value === 'pending') {
                return 'warning';
            } else if (value === 'canceled') {
                return 'danger';
            } else {
                return value;
            }
        };

        const transactionStatus = (status) => {
            if (status === 'pending') {
                return i18n.global.t('status.pending');
            } else if (status === 'canceled') {
                return i18n.global.t('status.canceled');
            } else {
                return i18n.global.t('status.successful');
            }
        };

        return {
            formatDate,
            getBadgeColor,
            transactionStatus
        };
    }
};
</script>

<style lang="scss" scoped>
.transaction {
    display: grid;
    grid-gap: 18px;
    margin-top: 6px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--field-bg, $field-bg);

    & > .cell {
        &.address {
            grid-area: adr;
            font-weight: 600;
            text-overflow: ellipsis;
            font-size: var(--text-xs, $text-xs);
            color: var(--main-text-color, $main-text-color);
        }

        &.amount {
            grid-area: amt;
            justify-content: right;
        }

        &.date {
            grid-area: dte;
            color: var(--title-text-color, $title-text-color);

            & > .g-symbol {
                fill: var(--title-text-color, $title-text-color);
            }
        }

        &.status {
            grid-area: sts;
            justify-content: center;
        }

        &.direction {
            grid-area: dir;
            font-weight: 600;
            justify-content: center;
        }
    }
}

.title {
    gap: 6px;
    display: flex;
    align-items: center;
    color: var(--title-text-color, $title-text-color);

    & > .g-symbol {
        fill: var(--title-text-color, $title-text-color);
    }
}

@media (min-width: 845px) {
    .transaction {
        grid-template: 'adr dte amt sts dir' auto / auto 164px 1fr 100px 86px;
    }

    .cell {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}

@media (max-width: 844px) {
    .transaction {
        padding: 20px;
        grid-template:
            'adr adr' 1fr
            'amt sts' 1fr
            'dir dte' 1fr / 1fr 1fr;
    }

    .cell {
        &::before {
            padding: 0 5px;
            display: block;
            margin-bottom: 5px;
            content: attr(data-label);
            color: var(--disabled-color, $disabled-color);
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }
    }
}
</style>