<template>
    <div class="frame-wrapper">
        <div class="g-frame" ref="content" v-on:scroll="onscroll">
            <slot/>
        </div>
        <transition name="scrollbar">
            <div v-if="vBarShow" class="v-scrollbar"></div>
        </transition>
        <transition name="scrollbar">
            <div v-if="hBarShow" class="h-scrollbar"></div>
        </transition>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'GFrame',
    setup() {
        const vBarShow = ref(false);
        const hBarShow = ref(false);

        let yOffset = 0;
        let xOffset = 0;
        let timeout = null;
        let showBarTimeout = null;
        const onscroll = (event) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                vBarShow.value = yOffset !== event.target.scrollTop;
                if (vBarShow.value) {
                    vBarShow.value = true;
                    calculateScrollY(event.target);
                }
                hBarShow.value = xOffset !== event.target.scrollLeft;
                if (hBarShow.value) {
                    hBarShow.value = true;
                    calculateScrollX(event.target);
                }
                clearTimeout(showBarTimeout);
                showBarTimeout = setTimeout(() => {
                    vBarShow.value = false;
                    hBarShow.value = false;
                }, 1500);
            }, 10);
        };

        const vOffset = ref('0');
        const hOffset = ref('0');
        const vBarHeight = ref('0');
        const hBarWidth = ref('0');

        const calculateScrollY = (target) => {
            yOffset = target.scrollTop;
            const height = Math.pow(target.clientHeight, 2) / target.scrollHeight;
            vBarHeight.value = `${height}px`;
            vOffset.value = `${yOffset * (height / target.clientHeight)}px`;
        };
        const calculateScrollX = (target) => {
            xOffset = target.scrollLeft;
            const width = Math.pow(target.clientWidth, 2) / target.scrollWidth;
            hBarWidth.value = `${width}px`;
            hOffset.value = `${xOffset * (width / target.clientWidth)}px`;
        };

        return {
            onscroll,
            vBarShow,
            hBarShow,
            vOffset,
            hOffset,
            hBarWidth,
            vBarHeight
        };
    },
};
</script>

<style lang="scss" scoped>
.frame-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: inherit;

    & > .g-frame {
        width: 100%;
        height: 100%;
        overflow: auto;
        scrollbar-width: none;
        box-sizing: border-box;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-corner {
            background-color: transparent;
            display: none;
        }
    }
}

.v-scrollbar,
.h-scrollbar {
    position: absolute;
    transition: opacity 0.5s;
    background-color: inherit;

    &::after,
    &::before {
        content: '';
        position: absolute;
    }
}

.v-scrollbar {
    top: 0;
    right: 0;
    width: 14px;
    height: 100%;

    &::before {
        left: 50%;
        height: 100%;
        transform: translateX(-50%);
        width: var(--scrollbar-size, $scrollbar-size);
        background-color: var(--scrollbar-color, $scrollbar-color);
    }

    &::after {
        left: 50%;
        height: v-bind(vBarHeight);
        transform: translate(-50%, v-bind(vOffset));
        width: var(--scrollbar-thumb-size, $scrollbar-thumb-size);
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.h-scrollbar {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 14px;

    &::before {
        top: 50%;
        width: 100%;
        height: 1px;
        transform: translateY(-50%);
        background-color: var(--scrollbar-color, $scrollbar-color);
    }

    &::after {
        top: 50%;
        width: v-bind(hBarWidth);
        transform: translate(v-bind(hOffset), -50%);
        height: var(--scrollbar-thumb-size, $scrollbar-thumb-size);
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.scrollbar-enter-active {
    transition: opacity 0.5s;
}

.scrollbar-enter-from,
.scrollbar-leave-active {
    opacity: 0;
    transition: opacity 0.5s;
}
</style>
