<template>
    <div class="wallets-dashboard" v-if="!wallet.isLoaded">
        <g-preloader is-dark/>
    </div>
    <div v-else class="wallets-dashboard">
        <g-caption size="6" weight="500">{{ $t('dashboard.wallet') }}</g-caption>

        <g-select v-model="walletId"
                  v-model:index="walletIndex"
                  v-bind:label="$t('label.wallet_name')"
                  v-bind:options="wallet.state"
                  option-text="name"
                  option-value="id"
                  size="large"/>

        <wallet-card v-bind:symbol="wallet.item(walletIndex).coin.symbol"
                     v-bind:network="wallet.item(walletIndex).coin.network"
                     v-bind:wallet-name="wallet.item(walletIndex).name"/>

        <g-button size="large"
                  icon-color="primary"
                  v-on:click="PopUp.open(require('@wallets/components/parts/SendTransaction')).setCaption($t('popupTitle.send')).setPayload({ wallet: wallet.item(walletIndex) })">
            {{ $t('button.send') }}
            <g-symbol name="card-send" width="22" height="22"/>
        </g-button>
        <g-button size="large"
                  icon-color="success"
                  v-on:click="PopUp.open(require('@wallets/components/parts/Receive')).setCaption($t('label.receive')).setPayload({ address: wallet.item(walletIndex).address })">
            {{ $t('button.receive') }}
            <g-symbol name="card-receive" width="22" height="22"/>
        </g-button>
    </div>

</template>

<script>
import { ref, watch } from 'vue';
import { PopUpManager as PopUp } from '@/Ship';
import { useWalletComponent, useWalletAction } from '@wallets';

const getWalletResource = useWalletAction('getWalletResource');

export default {
    name: 'DashboardWallets',
    components: {
        WalletCard: useWalletComponent('WalletCard'),
    },

    setup() {

        const walletId = ref(0);
        const walletIndex = ref(0);

        const wallet = getWalletResource();

        watch(walletId, () => {
            wallet.index = walletIndex.value;
        });

        return {
            wallet,
            walletId,
            walletIndex,
            PopUp,
        };
    },
};
</script>

<style scoped lang="scss">
.wallets-dashboard {
    padding: 24px;
    border: 1px solid var(--disabled, $disabled);
    border-radius: var(--rounding-lg, $rounding-lg);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;

    .g-caption {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    .g-select {
        flex-basis: 100%;
        margin-bottom: 25px;
    }

    .g-button {
        width: calc(50% - 5px);
        padding: 0 4px;
    }

    .wallet-card {
        flex-basis: 100%;
        margin: 0 auto 25px;
    }
}
</style>
