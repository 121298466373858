<template>
    <div class="g-caption" :class="[`h${size}`]">
        <slot/>
    </div>
</template>

<script>

export default {
    name: 'GCaption',
    props: {
        size: {
            default: '1'
        },
        weight: {
            default: '400'
        }
    },
};
</script>

<style lang="scss" scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: var(--font-primary, $font-primary);
    line-height: 130%;
    font-weight: v-bind(weight);
}

.h1 {
    font-size: var(--heading-h1, $heading-h1);
    padding: 15px 0;
}

.h2 {
    font-size: var(--heading-h2, $heading-h2);
    padding: 12px 0;
}

.h3 {
    font-size: var(--heading-h3, $heading-h3);
    padding: 10px 0;
}

.h4 {
    font-size: var(--heading-h4, $heading-h4);
    padding: 5px 0;
}

.h5 {
    font-size: var(--heading-h5, $heading-h5);
    padding: 3px 0;
}

.h6 {
    font-size: var(--heading-h6, $heading-h6);
    padding: 3px 0;
}

.bold {
    font-weight: 700;
}

.uppercase {
    text-transform: uppercase;
}
</style>