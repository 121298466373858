<template>
    <fieldset class="g-field" :class="[size, {'error': errors.length}]">
        <legend v-if="label">
            {{ label }}
            <g-hint hint-icon="alert" v-if="errors.length">
                <span v-for="(error, i) of errors" :key="error + i">{{ error }}</span>
            </g-hint>
            <slot v-else name="hint"></slot>
        </legend>
        <div>
            <input v-bind:value="modelValue"
                   v-on:input="inputEvent"
                   v-bind="$attrs">
            <slot/>
        </div>
    </fieldset>
</template>
<script>
export default {
    name: 'GField',
    inheritAttrs: false,
    props: {
        size: {
            type: String,
            default: 'large',
        },
        modelValue: { type: String },
        label: { type: String },
        errors: {
            type: Array,
            default: () => []
        },

    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const inputEvent = event => emit('update:modelValue', event.target.value);

        return {
            inputEvent
        };
    }
};
</script>

<style lang="scss" scoped>
.g-field {
    margin: 2px 0;
    padding: 0;
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);
    transition: all 0.2s;

    &:focus-within {
        border-color: var(--secondary, $secondary);
    }

    legend {
        color: var(--input-label-color, $input-label-color);
        margin-left: 14px;
        font-size: var(--label-font-size, $label-font-size);
        padding: 1px 3px;
        fill: var(--secondary, $secondary);
        display: inline-flex;
        align-items: center;
        position: relative;
        z-index: 2;

        &:deep(svg) {
            cursor: pointer;
        }

        &:hover {
            z-index: 3;
        }
    }

    & > div {
        display: flex;
        align-items: center;
        fill: var(--secondary, $secondary);
        position: relative;
        margin-top: -9px;

        & > input {
            width: 100%;
            border: none;
            flex-grow: 1;
            font-size: var(--input-font-size, $input-font-size);
            color: var(--main-text-color, $main-text-color);
            font-weight: 400;
            font-family: var(--font-primary, $font-primary);
            padding: 0 15px;
            box-sizing: border-box;
            background-color: transparent;
            height: var(--input-height-large, $input-height-large);

            &:focus {
                outline: none;
            }

            &:-webkit-autofill {
                -webkit-box-shadow: inset 0 0 0 9999px white;
            }

            &::placeholder {
                color: var(--input-hint-color, $input-hint-color);
                opacity: 1;
            }
        }

        & > input:focus ~ span {
            border-color: var(--secondary, $secondary);
        }

        &:deep(svg) {
            margin-right: 11px;
        }
    }

    &.large {
        & > div {
            & > input {
                height: var(--input-height-large, $input-height-large);
            }
        }
    }

    &.medium {
        & > div {
            & > input {
                height: var(--input-height-medium, $input-height-medium);
            }
        }
    }

    &.small {
        & > div {
            & > input {
                height: var(--input-height-small, $input-height-small);
                font-size: var(--input-font-size-sm, $input-font-size-sm);
            }
        }
    }

    &.error {
        border-color: var(--danger, $danger) !important;

        & > legend {
            color: var(--danger, $danger) !important;

            &:deep(svg) {
                fill: var(--danger, $danger) !important;
            }
        }
    }
}

.g-field-icon {
    & > div {
        & > input {
            padding: 0 15px 0 5px;
        }
    }
}

</style>
