<template>
    <img v-bind:src="qrCodeLink" v-bind:alt="address">
    <div class="text-center">
        <g-copy v-bind:text="address" class="address"/>
    </div>
</template>

<script>

import { computed } from 'vue';

export default {
    name: 'Receive',
    components: {},
    props: ['address'],

    setup(props) {
        const qrCodeLink = computed(() => {
            const qrCodeGeneratorService = 'https://chart.googleapis.com/chart?cht=qr&chs=484x484&chl=';
            return qrCodeGeneratorService.concat(props.address);
        });

        return {
            qrCodeLink
        };
    }
};
</script>

<style scoped lang="scss">
.text-center {
    text-align: center;
}

.address {
    font-size: 20px;
}
</style>