import componentLoader from '@/Ship/core/ComponentLoader';
import actionLoader from '@/Ship/core/ActionLoader';
import TaskLoader from '@/Ship/core/TaskLoader';

export function useAuthenticationAction(ask) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).action(ask);
}

export function useAuthenticationActions() {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).list();
}

export function useAuthenticationTask(ask) {
    const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
    return TaskLoader(requireComponents).task(ask);
}

export function useAuthenticationTasks() {
    const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
    return TaskLoader(requireComponents).list();
}

export function useAuthenticationComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useAuthenticationComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export default require('./View').default;
