export default function (requireResources) {
    return {
        list() {
            let resourceList = {};
            requireResources.keys().forEach(fileName => {
                const resourceName = fileName.split('/').pop().replace(/\.\w+$/, '');
                resourceList[resourceName] = requireResources(fileName).default;
            });

            return resourceList;
        },
        resource(ask) {
            const resource = requireResources.keys().find(fileName => {
                return new RegExp(`${ask}.js`).test(fileName);
            });

            return requireResources(resource).default.getInstance();
        }
    };
}