<template>
    <div class="preloader-overlay">
        <g-preloader :size="size" :is-dark="isDark"/>
    </div>
</template>

<script>
export default {
    name: 'g-preloader-overlay',
    props: {
        size: {
            type: Number,
            default: 80,
        },
        isDark: {
            type: Boolean,
            default: false,
        }
    },
    setup() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.preloader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 4;

    :deep(.g-preloader) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>