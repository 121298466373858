<template>
    <div class="history-filter">
        <g-select v-model="filters.type"
                  v-bind:label="$t('label.type')"
                  v-bind:options="types"
                  v-on:change="filtering"
                  option-text="label"
                  option-value="value"
                  size="large"/>
        <g-select v-model="filters.coin_id"
                  v-bind:label="$t('label.currency')"
                  v-bind:options="currencyList"
                  v-on:change="filtering"
                  option-text="name"
                  option-value="id"
                  size="large"/>
        <g-select v-model="filters.oauth_client_id"
                  v-bind:label="$t('label.client')"
                  v-on:change="filtering"
                  :options="clientList"
                  option-text="name"
                  option-value="id"/>

        <g-fade-container icon="calendar" v-model:is_show="isShowDatepicker">
            <g-date-picker v-on:change="setDateRange"
                           v-model:from="filters.from"
                           v-model:to="filters.to"/>
        </g-fade-container>

        <g-input v-model="filters.search" v-bind:label="$t('label.search')"
                 v-on:input="searchTransactions($event.target.value)" type="search">
            <g-symbol v-if="filters.search" name="close" v-on:click="searchTransactions(null)" class="drop-search"
                      width="24" height="24"/>
            <g-symbol v-else name="search" width="24" height="24"/>
        </g-input>
    </div>
</template>

<script>

import { useWalletActions } from '@wallets';
import { useHistoryActions, useHistoryResource } from '@history';
import { computed, onMounted, reactive, ref } from 'vue';
import { useProfileResource } from '@profile';

const { reloadTransactions } = useHistoryActions();

const { getCoinResource } = useWalletActions();
const transaction = useHistoryResource('Transaction');

export default {
    name: 'HistoryFilter',
    components: {},
    setup() {
        const isShowDatepicker = ref(false);
        const filters = reactive({});
        transaction.onload = function () {
            Object.assign(filters, transaction.filters);
        };
        const types = [
            { value: null, label: 'All' },
            { value: 'in', label: 'Receive' },
            { value: 'out', label: 'Withdraw' },
        ];

        const coin = getCoinResource();
        const currencyList = computed(() => {
            return [{ id: null, name: 'All', symbol: 'All' }, ...coin.state];
        });

        const client = useProfileResource('Client');
        const clientList = computed(() => {
            return [{ id: null, name: 'All', symbol: 'All' }, ...client.state];
        });



        let timeout;

        function searchTransactions(value) {
            filters.search = value;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                reloadTransactions(filters);
            }, 3000);
        }

        function filtering() {
            transaction.setFilters(filters);
            transaction.load();
        }

        function setDateRange() {
            isShowDatepicker.value = false;
            filtering();
        }

        onMounted(()=>{
            Object.assign(filters, transaction.filters);
        });


        return {
            filters,
            types,
            currencyList,
            isShowDatepicker,
            clientList,
            filtering,
            setDateRange,
            searchTransactions,
        };
    }

};
</script>

<style lang="scss" scoped>
.history-filter {
    display: flex;
    justify-content: space-between;
    gap: 23px;

    .g-select {
        margin: 0;
    }

    .g-input {
        margin: 0 0 0 auto;
        width: 100%;
        max-width: 350px;
    }
}

.drop-search {
    cursor: pointer;
}

@media (min-width: 1100px) {
    .history-filter .g-select {
        max-width: 185px;
    }
}

@media (max-width: 1100px) {
    .history-filter .g-input {
        max-width: 300px;
    }

    .history-filter {
        gap: 10px;
    }
}

@media (max-width: 820px) {
    .history-filter {
        flex-wrap: wrap;
        gap: 12px;
    }

    .history-filter .g-select {
        width: calc(50% - 6px);
    }

    .history-filter .g-input {
        max-width: 100%;
        min-width: 0;
    }

    .g-fade-container {
        order: 3;
    }

    @media (min-width: 500px) {
        .history-filter .g-input {
            flex: 1;
        }
    }
}

@media (max-width: 500px) {
    .history-filter .g-input {
        order: 4;
    }

    .history-filter .g-select:nth-child(3) {
        flex: 1;
        width: 100%;
    }
}

</style>