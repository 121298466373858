<template>
    <div class="reset-password">
        <ul class="list-step">
            <li v-bind:class="{active: step === 1 }"></li>
            <li v-bind:class="{active: step === 2 }"></li>
        </ul>

        <form class="step-container" v-if="step===1" v-on:submit.prevent="forgot">
            <div>
                <g-caption size="4" weight="700" v-html="$t('page.reset_password')"></g-caption>
                <div class="subtitle">{{ $t('authentication.reset_sub_title_email') }}</div>
            </div>

            <g-input v-model="email" v-bind:label="$t('label.email')" type="email"/>

            <g-button fill="primary" size="large" v-bind:disabled="!/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(email)">
                <g-symbol name="send" width="24" height="24"/>
                {{ $t('button.send') }}
            </g-button>
        </form>

        <form v-else-if="step===2" class="step-container" v-on:submit.prevent="reset">
            <div>
                <g-caption size="4" weight="700">{{ $t('page.enter_code') }}</g-caption>
                <div class="subtitle">
                    {{ $t('authentication.reset_sub_title_code') }}
                    <div>{{ email }}</div>
                </div>
            </div>

            <verification-code v-model="code" v-model:filled="codeFilled"/>

            <div class="subtitle">{{ $t('authentication.reset_sub_title_password') }}</div>
            <password-input v-model="password" v-model:complex="isComplex" v-bind:label="$t('label.new_password')"/>

            <g-button fill="primary" size="large" v-bind:disabled="!codeFilled || !isComplex">
                {{ $t('button.change') }}
            </g-button>

            <div class="subtitle">
                {{ $t('authentication.timer') }}
                <span>59:00</span>
            </div>
        </form>
    </div>
</template>

<script>
import { ref } from 'vue';
import {
    useAuthenticationActions,
    useAuthenticationComponents,
} from '@auth';
import {useRouter} from 'vue-router';

const {
    PasswordInput,
    VerificationCode,
} = useAuthenticationComponents();

export default {
    name: 'ResetPassword',
    components: {
        PasswordInput,
        VerificationCode,
    },

    setup() {
        const step = ref(1);
        const code = ref(null);
        const email = ref('');
        const password = ref('');

        const codeFilled = ref(false);
        const isComplex = ref(false);

        const router = useRouter();

        const {
            resetPassword,
            forgotPassword,
        } = useAuthenticationActions();

        function forgot () {
            forgotPassword(email.value).then(() => {
                step.value = 2;
            });
        }
        function reset () {
            resetPassword({
                code: code.value,
                password: password.value,
            }).then(() => {
                router.push({name: 'Dashboard', params: router.currentRoute.value.params});
            });
        }

        return {
            step,
            code,
            codeFilled,
            email,
            password,
            isComplex,
            reset,
            forgot,
        };
    },

};
</script>

<style lang="scss" scoped>
.reset-password {
    padding: 0 18px;
    max-width: 420px;
    text-align: center;
    box-sizing: border-box;
}

.step-container {
    width: 100%;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    justify-content: center;
}

.g-input,
.g-button,
.password-input,
.verification-code {
    flex-basis: 100%;
}

.g-caption {
    text-align: center;
    color: var(--home-page-color, $home-page-color);
}

.subtitle {
    color: var(--home-page-sub-title-color, $home-page-sub-title-color);

    & > div,
    & > span {
        color: var(--home-page-color, $home-page-color);
    }
}

.list-step {
    display: flex;
    gap: 10px;
    justify-content: center;
    list-style: none;
    margin-bottom: 24px;

    li {
        width: 40px;
        height: 6px;
        border-radius: 3px;
        background: var(--home-page-button-login, $home-page-button-login);

        &.active {
            background: var(--primary, $primary);
        }
    }
}
</style>
