<template>
    <div style="width: 100%" :class="{disabled: disabled}">
        <LineChart v-bind="lineChartProps"/>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { LineChart, useLineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { useI18n } from 'vue-i18n';
import { STATE } from '@/Ship';

Chart.register(...registerables);
export default {
    name: 'GCharts',
    components: {
        LineChart,
    },

    props: ['disabled'],

    setup() {
        const textColor = ref(getComputedStyle(document.documentElement).getPropertyValue('--main-text-color'));
        const gridColor = ref(getComputedStyle(document.documentElement).getPropertyValue('--separator-color'));

        watch(
            () => STATE.IS_DARK_THEME,
            () => {
                textColor.value = getComputedStyle(document.documentElement).getPropertyValue('--main-text-color');
                gridColor.value = getComputedStyle(document.documentElement).getPropertyValue('--separator-color');
            }
        );

        const dataValues = ref([3, 3.5, 1.5, 6, 5, 3, 5.5]);
        const dataValues2 = ref([7, 2.8, 5, 6.8, 4, 7, 3.5]);
        const dataLabels = ref([
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July'
        ]);
        const toggleLegend = ref(true);

        const { t } = useI18n();

        const testData = computed(() => ({
            type: 'line',
            labels: dataLabels.value,
            datasets: [
                {
                    label:  t('chart.label_1'),
                    data: dataValues.value,
                    borderColor: '#194BFB',
                    backgroundColor: ['#194BFB'],
                    pointStyle: 'circle',
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 15,
                    tension: 0.18
                },
                {
                    label: t('chart.label_2'),
                    data: dataValues2.value,
                    borderColor: '#D8E3F8',
                    backgroundColor: [
                        '#D8E3F8',
                    ],
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 15,

                    borderWidth: 2,
                    hitRadius: 12,
                    font: 'Poppins',
                    boxWidth: 40,
                    boxHeight: 100,
                }
            ],
        }));

        const options = computed(() => ({
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
            },
            scales: {
                x: {
                    display: true,
                    grid: {
                        display: true,
                        color: gridColor.value,
                    },
                    title: {
                        display: false,
                        text: 'Months'
                    }
                },
                y: {
                    display: true,
                    grid: {
                        display: true,
                        color: gridColor.value,
                    },
                    title: {
                        display: false,
                        text: 'Value'
                    },
                    suggestedMin: 0,
                    suggestedMax: 10
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: toggleLegend.value ? 'top' : 'bottom',
                    fontColor: 'red',
                    labels: {
                        pointRadius: 12,
                        color: textColor.value,
                        font: 'Poppins',
                        boxWidth: 12,
                        boxHeight: 4,
                        borderRadius: 5,
                        padding: 20
                    },
                    lineWidth: 40,
                    borderWidth: 120,
                    font: 'Poppins',
                    boxWidth: 12,
                    boxHeight: 4,
                },
                title: {
                    display: false,
                    lineWidth: 10,
                },
            },

        }));

        const { lineChartProps, lineChartRef } = useLineChart({
            chartData: testData,
            options,

        });

        return {
            testData,
            options,
            lineChartRef,
            lineChartProps,
        };
    },
};
</script>

<style lang="scss" scoped>
.disabled {
    opacity: 0.2;
    filter: grayscale(1);
    pointer-events: none;
}
</style>
