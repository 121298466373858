<template>
    <span class="g-span"><slot/></span>
</template>

<script>

import { computed, toRefs } from 'vue';

export default {
    name: 'GSpan',
    props: {
        fontSize: {
            type: String
        },

        bold: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { fontSize, bold } = toRefs(props);

        const textSize = computed(() => {
            return fontSize.value + 'px';
        });

        const textWeight = computed(() => {
            return bold.value ? 700 : 'inherit';
        });

        return {
            textSize,
            textWeight
        };
    }
};
</script>

<style lang="scss" scoped>
.g-span {
    font-size: v-bind(textSize);
    font-weight: v-bind(textWeight);
}
</style>