<template>
    <div class="wallet-card" v-bind:class="network.toLowerCase()">
        <svg class="logo" height="17" width="60">
            <use xlink:href="@assets/img/logo-full-white.svg#full-logo"/>
        </svg>

        <div class="card-name">{{ walletName }}</div>

        <div v-if="isInternal" class="balance">
            <div class="balance-title">
                {{ $t('wallet.balance') }}
                <g-symbol v-on:click="isShowBalance = !isShowBalance"
                          v-bind:name="isShowBalance ? 'eye-open' : 'eye-close'"
                          height="14"
                          width="14"/>
            </div>
            <div class="balance-value">{{ getBalanceValue }} {{ symbol }}</div>
        </div>

        <svg class="icon" height="110" width="95">
            <use :xlink:href="require(`@assets/img/currency-card-sprite.svg`) + `#${symbol.toLowerCase()}`"/>
        </svg>
    </div>
</template>

<script>

import { computed, ref } from 'vue';

export default {
    name: 'WalletCard',
    props: {
        symbol: {
            type: String,
        },
        network: {
            type: String,
        },
        balanceValue: {
            type: String,
            default: '00.00000000',
        },
        walletName: {
            type: String,
            default: '',
        },
        isInternal: {
            type: Boolean,
            default: true,
        }
    },

    setup(props) {

        const isShowBalance = ref(false);

        const getBalanceValue = computed(() => {
            const balance = props.balanceValue;
            return isShowBalance.value
                ? balance
                : '*****';
        });

        return {
            isShowBalance,
            getBalanceValue
        };
    }
};
</script>

<style lang="scss" scoped>
.wallet-card {
    border-radius: 18px;
    position: relative;
    padding: 20px;
    background-color: var(--secondary, $secondary);
    color: var(--payment-card-color, $payment-card-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: var(--text-xl, $text-xl);
    aspect-ratio: 15 / 9;
    max-width: 387px;

    .logo {
        top: 22px;
        right: 22px;
        position: absolute;
        width: 60px;
    }

    .card-name {
        font-size: 22px;
        max-width: calc(100% - 64px);
    }

    .balance {
        font-size: 22px;

        &-title {
            opacity: 0.6;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: var(--text-xs, $text-xs);
        }

        & svg {
            fill: white;
            margin-left: 6px;
            cursor: pointer;
        }
    }

    &.btc {
        background-color: var(--btc, $btc);
    }

    &.eth {
        background-color: var(--eth, $eth);
    }

    &.trx {
        background-color: var(--trx, $trx);
    }

    &.usdt {
        background-color: var(--usdt, $usdt);
    }

    //&.xtz {
    //    background-color: var(--xtz, $xtz);
    //}

    //&.uni {
    //    background-color: var(--uni, $uni);
    //}

    .icon {
        position: absolute;
        right: 10px;
        bottom: 20px;
    }
}
</style>