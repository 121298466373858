<template>
    <div class="env-topbar">
        <g-button @click="STATE.IS_SHOW_LEFTBAR = true"
                  class="button-hamburger"
                  size="small"
                  square>
            <span></span>
            <span></span>
            <span></span>
        </g-button>
        <slot/>
        <div class="env-toolbar toolbar">
            <g-lang-select :langList="langList"/>
            <div class="save-indicator">
                <g-button round size="medium" class="button-save" :fill="saveStatus">
                    <g-preloader :size="48" v-if="STATE.IS_PROCESSING"/>
                    <g-symbol v-if="STATE.IS_PROCESSING" name="info" width="24" height="24"/>
                    <g-symbol v-else name="import" width="24" height="24"/>
                </g-button>
            </div>
            <div class="user-profile" ref="user">
                <div class="user" :class="{active:isProfileMenu, 'active-page': route.name === 'Profile'}"
                     @click="isProfileMenu=!isProfileMenu">
                    <div class="user-avatar">
                        <g-symbol name="profile" width="18" height="18"/>
                    </div>
                    <span>{{ profile.model.first_name }} {{ profile.model.last_name }}</span>
                    <g-symbol name="arrow-down" width="18" height="18"/>
                </div>
                <div v-if="isProfileMenu" class="profile-menu">
                    <div class="name">{{ profile.model.name }}</div>
                    <div class="prof">{{ profile.model.username }}</div>
                    <ul class="menu-list">
                        <li>
                            <router-link
                                :class="{ active: route.name === 'Profile' }"
                                :to="{ name: 'Profile', params: router.currentRoute.value.params }"
                            >
                                <g-symbol name="profile" width="24" height="24"/>
                                {{ $t('profileMenu.profile') }}
                            </router-link>
                        </li>
                        <li v-on:click="logout">
                            <g-symbol name="logout" width="24" height="24"/>
                            {{ $t('profileMenu.logout') }}
                        </li>
                    </ul>
                    <div class="row">
                        {{ $t('profileMenu.dark_mode') }}
                        <g-switch @update:checked="onChangeTheme($event)" :checked="STATE.IS_DARK_THEME"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthenticationActions } from '@auth';
import { useProfileResource } from '@profile';
import { STATE } from '@/Ship';

export default {
    name: 'EnvTopbar',
    components: {},
    setup() {
        const isProfileMenu = ref(false);
        const statusKey = [{ success: 'send' }, { warning: 'pending' }, { success: 'saved' }];

        const router = useRouter();
        const { signOut } = useAuthenticationActions();

        const logout = () => {
            signOut();
            setTimeout(() => router.push({ name: 'Dashboard' }), 0);
        };



        const langList = computed(() => {
            return process.env.VUE_APP_AVAILABLE_LOCALES.split('|');
        });

        const profile = useProfileResource('Profile');

        const saveStatus = ref('');
        watch(
            () => STATE.AUTO_SAVE,
            () => {
                if (!STATE.AUTO_SAVE) {
                    saveStatus.value = 'danger';
                } else {
                    saveStatus.value = 'success';
                    setTimeout(() => {
                        saveStatus.value = '';
                    }, 3000);
                }
            }
        );

        const user = ref(null);
        const documentClick = (e) => {
            if (!e.composedPath().includes(user.value)) {
                isProfileMenu.value = false;
            }
        };

        onMounted(() => {
            const activeTheme = STATE.IS_DARK_THEME ? 'dark' : 'light';
            document.documentElement.setAttribute('theme', activeTheme);
            
            window.addEventListener('click', documentClick);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('click', documentClick);
        });

        const onChangeTheme =  (checked) => {
            const activeTheme = checked ? 'dark' : 'light';
            STATE.IS_DARK_THEME = checked;
            localStorage.setItem('theme', activeTheme);
            document.documentElement.setAttribute('theme', activeTheme);
        };

        return {
            STATE,
            profile,
            logout,
            router,
            isProfileMenu,
            statusKey,
            langList,
            saveStatus,
            user,
            route: useRoute(),
            signOut,
            onChangeTheme
        };
    },
};
</script>

<style lang="scss" scoped>
.env-topbar {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--env-bg, $env-bg);

    &:deep(.toolbar) {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        &.part-toolbar {
            flex-grow: 1;
        }
    }

    .user {
        display: flex;
        align-items: center;
        height: 48px;
        background: var(--profile-menu-button-bg, $profile-menu-button-bg);
        border-radius: 50px;
        padding: 8px;
        font-weight: 600;
        font-size: var(--text-md, $text-md);
        line-height: 150%;
        color: var(--profile-menu-button-color, $profile-menu-button-color);
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.2s;

        & > svg {
            margin-left: 17px;
            fill: var(--profile-menu-button-color, $profile-menu-button-color);
            transition: all 0.2s;
        }

        &.active {
            background: var(--primary, $primary);
            color: var(--profile-menu-button-active-color, $profile-menu-button-active-color);

            & > svg {
                transform: rotate(-180deg);
                fill: var(--profile-menu-button-active-color, $profile-menu-button-active-color);
            }
        }

        &.active-page {
            background: var(--primary, $primary);
            color: var(--profile-menu-button-active-color, $profile-menu-button-active-color);

            &:hover {
                background: var(--primary-hover, $primary-hover);
            }

            & > svg {
                fill: var(--profile-menu-button-active-color, $profile-menu-button-active-color);
            }
        }
    }
}

.save-indicator {
    display: flex;
    align-items: center;

    svg {
        position: absolute;
    }
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--separator-color, $separator-color);
    margin-right: 10px;
}

.profile-menu {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    padding: 16px;
    width: 232px;
    background: var(--profile-menu-bg, $profile-menu-bg);
    box-shadow: var(--profile-menu-shadow, $profile-menu-shadow);
    border-radius: var(--profile-menu-rounding, $profile-menu-rounding);
    z-index: 10;
}

.name {
    font-weight: 600;
    font-size: var(--text-md, $text-md);
    color: var(--main-text-color, $main-text-color);
    margin-bottom: 6px;
}

.prof {
    font-weight: 400;
    font-size: var(--text-xs, $text-xs);
    color: var(--profile-menu-icon-color, $profile-menu-icon-color);
}

.menu-list {
    list-style: none;
    border-bottom: 1px solid var(--separator-color, $separator-color);
    margin: 0 0 13px;
    padding: 0 0 10px;

    li {
        font-weight: 400;
        font-size: var(--text-sm, $text-sm);
        display: flex;
        align-items: center;
        padding: 10px 0;
        transition: color 0.2s;

        &:last-child {
            cursor: pointer;
        }

        &:deep(svg) {
            fill: var(--profile-menu-icon-color, $profile-menu-icon-color);
            margin-right: 8px;
            transition: fill 0.2s;
        }

        &:hover {
            color: var(--primary, $primary);

            a {
                color: var(--primary, $primary);
            }

            &:deep(svg) {
                fill: var(--primary, $primary);
            }
        }

        a {
            color: var(--main-text-color, $main-text-color);
            display: flex;
            align-items: center;
            width: 100%;

            &.active {
                cursor: default;
                color: var(--primary, $primary);
                pointer-events: none;

                &:deep(svg) {
                    fill: var(--primary, $primary);
                }
            }
        }
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: var(--text-sm, $text-sm);
}

.button-hamburger.g-button {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
    min-width: 32px;

    span {
        display: block;
        width: 16px;
        height: 2px;
        background: var(--separator-color, $separator-color);
        margin: 2px 0;
    }
}

@media screen and (max-width: 1300px) and (min-width: 1101px) {
    .env-topbar {
        padding: 0 25px;
    }
}

@media screen and (min-width: 1101px) {
    .button-hamburger.g-button {
        display: none;
    }
}

@media screen and (max-width: 1100px) {
    .env-topbar {
        padding: 20px;
    }
}

@media screen and (max-width: 600px) {
    .user {
        span {
            display: none;
        }

        & > svg {
            display: none;
        }
    }

    .user-avatar {
        margin-right: 0;
    }

    .env-topbar .toolbar {
        gap: 10px;
    }

    .button-hamburger.g-button {
        margin-right: 10px;
    }
}

@media screen and (max-width: 359px) {
    .env-topbar .toolbar {
        gap: 7px;
    }

    .env-topbar {
        padding: 20px 10px;
    }

    .env-topbar .user {
        padding: 0;
        height: auto;
    }

    .button-hamburger.g-button {
        margin-right: 7px;
    }
}
</style>
