import { STATE } from '@/Ship';
import { useAuthenticationTask } from '@auth';

export default function () {
    STATE.IS_LOGIN = false;

    STATE.IS_DARK_THEME = false;
    localStorage.setItem('theme', 'light');
    document.documentElement.setAttribute('theme', 'light');

    const deleteToken = useAuthenticationTask('deleteToken');
    deleteToken();
}
