import componentLoader from '@/Ship/core/ComponentLoader';
import actionLoader from '@/Ship/core/ActionLoader';
//import TaskLoader from "@/Ship/core/TaskLoader";
import resourceLoader from '@/Ship/core/ResourceLoader';
import typeLoader from '@/Ship/core/TypeLoader';

export function useHistoryAction(ask) {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).action(ask);
}

export function useHistoryActions() {
    const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
    return actionLoader(requireActions).list();
}

// export function useHistoryTask(ask) {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).task(ask);
// }

// export function useHistoryTasks() {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).list();
// }

export function useHistoryResource(ask) {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).resource(ask);
}

export function useHistoryResources() {
    const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
    return resourceLoader(requireResources).list();
}

export function useHistoryComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useHistoryComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export function useHistoryType(ask) {
    const requireTypes = require.context('./enums', true, /[\w-]+\.js$/);
    return typeLoader(requireTypes).type(ask);
}

export function useHistoryTypes() {
    const requireTypes = require.context('./enums', true, /[\w-]+\.js$/);
    return typeLoader(requireTypes).list();
}

export default require('./View').default;
