<template>
    <component v-if="route.name === 'Dashboard'" :is="STATE.IS_LOGIN ? 'Dashboard' : 'Home'"/>
    <router-view v-else/>
    <div class="navbar-mask" v-if="STATE.IS_SHOW_LEFTBAR || route.name === 'Transaction'" @click="hideMask"></div>
</template>

<script>

import Dashboard from '@dashboard';
import { useRoute, useRouter } from 'vue-router';

import Home from '@home';
import { STATE } from '@/Ship';

export default {
    name: 'Environment',
    components: {
        Home,
        Dashboard,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();

        const hideMask = () => {
            STATE.IS_SHOW_LEFTBAR = false;

            if(route.name === 'Transaction'){
                router.push({name: 'History', params: router.currentRoute.value.params});
            }
        };

        return {
            STATE,
            route,
            hideMask
        };
    },
};
</script>

<style lang="scss" scoped>
.navbar-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: var(--popup-overlay, $popup-overlay);
    z-index: 99;
}

@media screen and (min-width: 1101px) {
    .navbar-mask {
        display: none;
    }
}
</style>
