<template>
    <component
        :is="tag"
        class="g-flex"
        :class="[{'wrap': wrap}, {'inline': inline}, {[`direction-${direction}`]: direction}, {[`align-items-${align}`]: align}, {[`justify-content-${justify}`]: justify}]"
    >
        <slot/>
    </component>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
    name: 'GFlex',
    props: {
        wrap: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String
        },
        align: {
            type: String
        },
        justify: {
            type: String
        },
        gap: {
            type: Number,
            default: 0
        },
        tag: {
            type: String,
            default: 'div'
        }
    },
    setup(props) {
        const { gap } = toRefs(props);
        const flexGap = computed(() => {
            return gap.value + 'px';
        });

        return {
            flexGap
        };
    }
};
</script>

<style lang="scss" scoped>
.g-flex {
    gap: v-bind(flexGap);

    &:not(.inline) {
        display: flex;
    }
}

.inline {
    display: inline-flex;
}

.wrap {
    flex-wrap: wrap;
}

.direction-row {
    flex-direction: row;
}

.direction-column {
    flex-direction: column;
}

.direction-row-reverse {
    flex-direction: row-reverse;
}

.direction-column-reverse {
    flex-direction: column-reverse;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch;
}
</style>