
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();

export default class Client extends BaseResource {
    static #instance = null;
    endpoint = '/profile/api_clients';

    constructor() {
        super();
        if (!Client.#instance) {
            Client.#instance = this;
        }
        return Client.#instance;
    }

    static getInstance() {
        return new Client();
    }
}
