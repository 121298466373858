<template>
    <div class="g-preloader" :class="{'dark': isDark }">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>

import { computed } from 'vue';

export default {
    name: 'g-preloader',
    props: {
        size: {
            type: Number,
            default: 80,

        },
        isDark: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const getLoaderBoxSize = computed(() => {
            return `${props.size}px`;
        });

        const getLoaderSize = computed(() => {
            return `${props.size - (props.size * 0.20)}px`;
        });

        const getLoaderBorderWeight = computed(() => {
            return `${props.size - (props.size * 0.90)}px`;
        });

        const loaderColor = computed(() => {
            return props.isDark;
        });

        return {
            getLoaderBoxSize,
            getLoaderSize,
            getLoaderBorderWeight,
            loaderColor
        };
    },
};
</script>

<style lang="scss" scoped>
.g-preloader {
    display: block;
    margin: 0 auto;
    position: relative;
    width: v-bind(getLoaderBoxSize);
    height: v-bind(getLoaderBoxSize);
}

.g-preloader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: v-bind(getLoaderSize);
    height: v-bind(getLoaderSize);
    margin: v-bind(getLoaderBorderWeight);
    border: v-bind(getLoaderBorderWeight) solid;
    border-radius: 50%;
    animation: g-preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $field-bg transparent transparent transparent;
}

.dark div {
    border-color: $primary transparent transparent transparent;
}

.g-preloader div:nth-child(1) {
    animation-delay: -0.45s;
}

.g-preloader div:nth-child(2) {
    animation-delay: -0.3s;
}

.g-preloader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes g-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>