<template>
    <div class="g-fade-container" v-bind:class="[size, { 'disabled':  disabled}]" ref="wrap">
        <g-button :class="{'primary-fill': is_show}"
                  v-bind:size="size"
                  v-bind:disabled="disabled"
                  v-on:click="toggleVisibility"
                  square>
            <g-symbol :name="icon" width="24" height="24"/>
        </g-button>
        <transition name="fade">
            <div v-if="is_show"
                 class="container"
                 ref="container"
                 v-on:click.stop
                 v-bind:class="[positionX, positionY]">
            <slot/>
        </div>
        </transition>
    </div>
</template>

<script>

import { ref, watch } from 'vue';

export default {
    name: 'GFadeContainer',

    inheritAttrs: false,
    emits: ['update:is_show'],
    props: {
        is_show: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'large',
        },
        icon: {
            type: String,
            require: true,
        },
    },
    setup(props, { emit }) {
        const positionY = ref('top');
        const positionX = ref('right');
        const container = ref(null);
        const wrap = ref(null);

        const toggleVisibility = () => {
            if (!props.is_show) {
                setTimeout(() => {
                    const rect = container.value.getBoundingClientRect();
                    const rectWrap = wrap.value.getBoundingClientRect();
                    positionY.value = (rectWrap.y + rect.height) > document.body.offsetHeight
                        ? 'top'
                        : 'bottom';
                    positionX.value = (rectWrap.x + rect.width) > document.body.offsetWidth
                        ? 'left'
                        : 'right';
                    document.documentElement.onclick = toggleVisibility;
                }, 0);
            }

            emit('update:is_show', !props.is_show);
        };

        watch(() => props.is_show, isShow => {
            if (!isShow) {
                document.documentElement.onclick = null;
            }
        });

        return {
            positionY,
            positionX,
            container,
            wrap,
            toggleVisibility
        };
    }
};
</script>

<style lang="scss" scoped>
.g-fade-container {
    position: relative;

    &.disabled {
        pointer-events: none;
    }
}

.container {
    position: absolute;
    transform: translateX(-50%);
    z-index: 100;

    &.bottom {
        top: calc(100% + 8px);
    }

    &.right {
        left: 0;
        transform: none;
    }

    &.left {
        right: 0;
        transform: none;
    }

    &.top {
        bottom: calc(100% + 8px);
    }
}

.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-active {
    opacity: 0;
    transition: opacity 0.3s;
}
</style>