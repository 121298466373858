
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();

export default class Wallet extends BaseResource {
    static #instance = null;
    endpoint = '/wallets';

    constructor() {
        super();
        if (!Wallet.#instance) {
            Wallet.#instance = this;
        }
        return Wallet.#instance;
    }

    static getInstance() {
        return new Wallet();
    }
}
