<template>
    <div class="g-input" :class="[size, {'error': error}, { disabled: disabled}]">
        <div class="input-row">
            <input v-bind:value="modelValue"
                   v-on:input="inputEvent"
                   v-bind="$attrs"
                   :disabled="disabled"
                   :placeholder="label">
            <label>{{ label }}</label>
            <slot/>
        </div>
        <div v-if="hint" class="hint-text">{{ hint }}</div>
    </div>

</template>

<script>
export default {
    name: 'GInput',
    inheritAttrs: false,
    props: {
        size: {
            type: String,
            default: 'large',
        },
        modelValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: { type: String },
        hint: { type: String },
        error: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const inputEvent = event => {
            emit('update:modelValue', event.target.value);
        };

        return {
            inputEvent
        };
    }
};
</script>

<style lang="scss" scoped>
.g-input {
    padding: 0;
    position: relative;

    .input-row {
        display: flex;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        color: var(--main-text-color, $main-text-color);
        fill: var(--input-label-color, $input-label-color);
        background-color: var(--input-bg, $input-bg);
        border: 1px solid var(--input-border, $input-border);
        border-radius: var(--input-rounding, $input-rounding);
        transition: border-color 0.2s;
    }

    label {
        position: absolute;
        height: 20px;
        font-size: var(--label-font-size, $label-font-size);
        color: var(--input-label-color, $input-label-color);
        line-height: 20px;
        letter-spacing: normal;
        padding: 0 20px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        pointer-events: none;
        transition: all 0.2s;
    }

    input {
        width: 100%;
        border: none;
        flex-grow: 1;
        font-size: var(--input-font-size, $input-font-size);
        color: var(--main-text-color, $main-text-color);
        font-weight: 400;
        font-family: var(--font-primary, $font-primary);
        padding: 22px 20px 0;
        box-sizing: border-box;
        background-color: var(--input-bg, $input-bg);
        height: var(--input-height-large, $input-height-large);
        border-radius: var(--input-rounding, $input-rounding);

        &::placeholder {
            color: transparent;
            opacity: 0;
        }

        &:not(:placeholder-shown) + label {
            top: 19px;
            font-size: var(--label-focus-font-size, $label-focus-font-size);
        }

        &:focus {
            outline: none;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: inset 0 0 0 9999px var(--input-bg, $input-bg);
        }
    }

    &:deep(svg) {
        margin-right: 18px;
    }

    &.error {
        .input-row {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--danger, $danger);
            box-shadow: 0 0 0 1px var(--danger, $danger);
        }

        input {
            background: var(--input-active-bg, $input-active-bg);
        }

        & div > label {
            color: var(--danger, $danger);
        }

        .hint-text {
            color: var(--danger, $danger);
        }
    }

    &:focus-within {
        .input-row {
            background: var(--input-active-bg, $input-active-bg);
            border-color: var(--secondary, $secondary);
            box-shadow: 0 0 0 1px var(--secondary, $secondary);
        }

        input {
            background: var(--input-active-bg, $input-active-bg);

            &:-webkit-autofill {
                -webkit-box-shadow: inset 0 0 0 9999px var(--input-active-bg, $input-active-bg);
            }
        }

        label {
            top: 19px;
            font-size: var(--label-focus-font-size, $label-focus-font-size);
            color: var(--primary, $primary);
        }
    }

    &.disabled {
        .input-row {
            background-color: var(--input-bg, $input-bg);
            pointer-events: none;
            opacity: 0.5;
        }

        input {
            color: var(--label-disabled-color, $label-disabled-color);
            background-color: var(--input-bg, $input-bg);
        }

        & > span {
            border-color: var(--input-border, $input-border);
        }

        label {
            color: var(--label-disabled-color, $label-disabled-color);
        }
    }

    &.large {
        input {
            height: var(--input-height-large, $input-height-large);
        }
    }

    &.medium {
        input:not(:placeholder-shown) + label {
            top: 13px;
        }

        &:focus-within label {
            top: 13px;
        }

        input {
            padding-top: 19px;
            height: var(--input-height-medium, $input-height-medium);
        }
    }

    &.small {
        input:not(:placeholder-shown) + label {
            display: none;
        }

        &:focus-within label {
            display: none;
        }

        label {
            font-size: var(--input-font-size-sm, $input-font-size-sm);
        }

        input {
            height: var(--input-height-small, $input-height-small);
            font-size: var(--input-font-size-sm, $input-font-size-sm);
            padding: 0 15px;
        }
    }

    &:deep(.button-input) {
        padding: 0;
        background: transparent;
        cursor: pointer;
        margin-right: 18px;

        &:hover {
            fill: var(--primary, $primary);
        }

        svg {
            margin-right: 0;
        }

        &.disabled {
            pointer-events: none;
            fill: var(--label-disabled-color, $label-disabled-color);
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.hint-text {
    font-size: var(--text-xs, $text-xs);
    line-height: 140%;
    font-weight: 400;
    color: var(--input-hint-color, $input-hint-color);
    margin-top: 6px;
}
</style>
