<template>
    <div class="password-input">
        <g-input v-bind:label="label"
                 v-bind:value="modelValue"
                 v-on:input="inputEvent"
                 v-bind:type="showPassword ? 'text' : 'password'">
            <g-symbol name="refresh"
                      width="25"
                      height="20"
                      v-on:click="generate"/>
            <g-symbol :name="showPassword ? 'eye-open' : 'eye-close'"
                      width="25"
                      height="20"
                      v-on:click="showPassword = !showPassword"/>
        </g-input>

        <ul class="password-level" v-bind:class="status">
            <li v-bind:class="{ active: level & 1 }"></li>
            <li v-bind:class="{ active: level & 2 }"></li>
            <li v-bind:class="{ active: level & 4 }"></li>
            <li v-bind:class="{ active: level & 8 }"></li>
        </ul>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useAuthenticationActions } from '@auth';

export default {
    name: 'PasswordInput',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
        },
        label: {
            type: String,
        },
        complex: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {
        const {
            validatePassword,
            generatePassword
        } = useAuthenticationActions();

        const level = ref(0);
        const showPassword = ref(false);

        const status = computed(() => {
            return {
                0: 'danger',
                1: 'danger',
                3: 'danger',
                7: 'warning',
                15: 'success',
            }[level.value];
        });

        const inputEvent = event => {
            emit('update:modelValue', event.target.value);
            validate(event.target.value);
        };

        function generate() {
            const password = generatePassword(10);
            emit('update:modelValue', password);
            validate(password);
        }

        function validate(password) {
            level.value = validatePassword(password);
            emit('update:complex', Boolean(level.value & 8));
        }

        return {
            level,
            status,
            showPassword,
            generate,
            inputEvent,
        };
    },
};
</script>
<style lang="scss" scoped>
.password-input {
    position: relative;

    &:deep(.g-input > span) {
        padding-right: 10px;
    }
}

svg {
    cursor: pointer;
}

.generate-btn {
    margin-right: 1rem;
    fill: var(--home-page-color, $main-text-color);

    &:active {
        fill: var(--primary, $primary);
    }
}

.show-btn {
    fill: var(--home-page-color, $main-text-color);

    &:active {
        fill: var(--primary, $primary);
    }
}

.password-level {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > li {
        margin: 0 4px;
        flex-grow: 1;
        height: 6px;
        border-radius: 3px;

        &:not(.active) {
            background-color: var(--home-page-color, $separator-color);
        }
    }

    &.success > li.active {
        background-color: var(--success, $success);
    }

    &.warning > li.active {
        background-color: var(--warning, $warning);
    }

    &.danger > li.active {
        background-color: var(--danger, $danger);
    }
}
</style>