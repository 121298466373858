import { useProfileResource } from '@profile';

export default () => {
    const profile = useProfileResource('Profile');
    if (!profile.isLoaded) {
        profile.load();
    }

    return profile;
};
