<template>
    <cover>
        <sign-in/>
    </cover>
</template>

<script>

import { useHomeComponent } from '@home';
import { useAuthenticationComponent } from '@auth';

export default {
    name: 'View',
    components: {
        Cover: useHomeComponent('Cover'),
        SignIn: useAuthenticationComponent('SignIn')
    }
};
</script>

<style lang="scss" scoped>

</style>