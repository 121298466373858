<template>
    <form class="sign-in" v-on:submit.prevent="login">
        <g-caption class="h4 center" weight="700">{{ $t('page.sign_in') }}</g-caption>

        <g-input v-model="email" v-bind:label="$t('label.email')" type="email" :error="signError"/>
        <g-input v-model="password"
                 v-bind:type="showPassword ? 'text' : 'password'"
                 v-bind:label="$t('label.password')"
                 :error="signError">
            <button type="button" class="button-input"
                    v-on:click="showPassword = !showPassword">
                <g-symbol :name="showPassword ? 'eye-open' : 'eye-close'" width="24" height="24"/>
            </button>
        </g-input>

        <g-checkbox v-bind:label="$t('authentication.remember')"/>
        <router-link v-bind:to="{ name: 'ResetPassword', params: router.currentRoute.value.params }" tag="a" class="link">
            {{ $t('authentication.forgot_password') }}
        </router-link>

        <g-button fill="primary" size="large">
            {{ $t('button.sign_in') }}
        </g-button>

        <div class="center">
            {{ $t('authentication.have_account') }}
            <router-link :to="{ name: 'SignUp', params: router.currentRoute.value.params }" tag="a" class="link">
                {{ $t('button.sign_up') }}
            </router-link>
        </div>
    </form>
</template>

<script>

import { ref } from 'vue';
import { useAuthenticationAction } from '@auth';
import { useRouter } from 'vue-router';

export default {
    name: 'SignIn',

    setup() {
        const email = ref('');
        const password = ref('');
        const showPassword = ref(false);
        const signError = ref(false);

        const signIn = useAuthenticationAction('signIn');

        const login = () => {
            const result = signIn(email.value, password.value);
            result.catch(() => {
                signError.value = true;
            });
        };

        return {
            email,
            password,
            router: useRouter(),
            showPassword,
            signError,
            login,
        };
    },
};
</script>

<style lang="scss" scoped>
.sign-in {
    padding: 0 18px;
    max-width: 460px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link {
    font-size: 106%;
    font-weight: 600;
    margin-left: 5px;
    color: var(--secondary, $secondary);
    transition: color 0.2s;

    &:hover {
        color: var(--primary, $primary);
    }
}

.center {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.g-button {
    margin: 25px 0;
    flex-basis: 100%;
}

.g-input {
    flex-basis: 100%;
    margin-bottom: 14px;
}

.g-caption {
    margin-bottom: 25px;
}
</style>