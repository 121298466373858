<template>
    <div class="send-detail">
        <ul class="detail-list">
            <li>
                <div class="title">
                    <g-symbol name="card-send" width="24" height="24"/>
                    {{ $t('transactionDetails.wallet') }}
                </div>
                <div class="value">{{ payload.name }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="chart-bar" width="24" height="24"/>
                    {{ $t('transactionDetails.blockchain') }}
                </div>
                <div class="value">{{ payload.currency }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="card-send" width="24" height="24"/>
                    {{ $t('transactionDetails.direction') }}
                </div>
                <div class="value">{{ payload.type }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="send" width="24" height="24"/>
                    {{ $t('transactionDetails.from_address') }}
                </div>
                <div class="value">{{ payload.address }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="send" width="24" height="24"/>
                    {{ $t('transactionDetails.to_address') }}
                </div>
                <div class="value">{{ payload.toAddress }}</div>
            </li>
        </ul>
        <ul class="detail-list">
            <li>
                <div class="title">
                    <g-symbol name="calendar" width="24" height="24"/>
                    {{ $t('transactionDetails.amount') }}
                </div>
                <div class="value">{{payload.amount}} {{ payload.currency }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="receipt" width="24" height="24"/>
                    {{ $t('transactionDetails.fee') }}
                </div>
                <div class="value">{{payload.fee}} {{ payload.currency }}</div>
            </li>
            <li>
                <div class="title">
                    <g-symbol name="receipt" width="24" height="24"/>
                    {{ $t('transactionDetails.total') }}
                </div>
                <div class="value total">0.214353 {{ payload.currency }}</div>
            </li>
        </ul>
        <g-button fill="primary" size="large" @click="sendTransaction">
            {{ $t('button.send_currency') }}
            <g-symbol name="card-send" width="24" height="24"/>
        </g-button>
    </div>
</template>

<script>
import { PopUpManager as PopUp } from '@/Ship';
import { useHistoryResource } from '@history';

export default {
    name: 'SendDetail',
    emits: ['performed'],
    props: {
        payload: {
            type: Object
        },
        wallet: {
            type: Object
        }
    },
    setup(props, {emit}) {
        const transaction = useHistoryResource('Transaction');

        function sendTransaction() {
            const transactionPayload = {
                wallet_id: props.payload.walletId,
                address: props.payload.toAddress,
                amount: props.payload.amount,
                fee: props.payload.fee
            };

            transaction.create(transactionPayload);
            emit('performed');
        }

        return {
            PopUp,
            sendTransaction
        };
    }
};
</script>

<style lang="scss" scoped>
.g-button {
    width: 100%;
    margin-top: 15px;
}

.row {
    display: flex;
    gap: 16px;

    & > .g-input,
    & > .g-select {
        margin-top: 0;
    }
}

.detail-list {
    list-style: none;
    padding-top: 24px;

    &:first-child {
        padding-top: 0;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
    }

    .title {
        font-weight: 400;
        font-size: var(--text-md, $text-md);
        line-height: 140%;
        color: var(--disabled-color, $disabled-color);
        display: flex;
        align-items: center;

        &:deep(svg) {
            fill: var(--disabled-color, $disabled-color);
            margin-right: 13px;
        }
    }

    .value {
        font-weight: 400;
        font-size: var(--text-sm, $text-sm);
        line-height: 140%;
        color: var(--main-text-color, $main-text-color);
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 6px;

        &.total {
            color: var(--primary, $primary);
            font-weight: 600;
        }
    }
}

.detail-header {
    display: flex;
    justify-content: space-between;

    .title {
        font-weight: 600;
        font-size: var(--text-lg, $text-md);
        line-height: 150%;
    }

    .g-button-link {
        font-size: var(--text-lg, $text-md);
        line-height: 150%;
        gap: 8px;
        text-decoration: none;
    }

    & + .detail-list {
        border-top: none;
        padding-top: 0;
    }
}

@media screen and (max-width: 500px) {
    .row {
        display: block;
    }
}
</style>