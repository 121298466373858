
import { useShipResources } from '@/Ship';
const { BaseResource } = useShipResources();

export default class Coin extends BaseResource {
    static #instance = null;
    endpoint = '/coins';

    constructor() {
        super();
        if (!Coin.#instance) {
            Coin.#instance = this;
        }
        return Coin.#instance;
    }

    static getInstance() {
        return new Coin();
    }
}
