<template>
    <label class="g-checkbox" :class="{ 'disabled':  disabled}">
        <input type="checkbox"
               v-bind="$attrs"
               @input="(event) => $emit('update:checked', event.target.checked)"
               :checked="checked"
               :disabled="disabled"
               hidden>
        <span class="icon"><g-symbol name="check" width="20" height="20"/></span>
        {{ label }}
        <span><slot/></span>
    </label>
</template>

<script>

export default {
    name: 'g-checkbox',
    props: {
        label: String,
        fieldId: {
            type: String,
        },
        checked: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    setup() {}
};
</script>

<style lang="scss" scoped>
.g-checkbox {
    cursor: pointer;
    font-size: var(--checkbox-font-size, $checkbox-font-size);
    font-weight: 400;
    display: inline-flex;
    align-items: center;

    .icon {
        margin-right: 6px;
        border-radius: var(--checkbox-rounding, $checkbox-rounding);
        background: var(--checkbox-bg, $checkbox-bg);
        transition: all 0.2s;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: transparent;

        &:hover {
            background: var(--checkbox-bg-hover, $checkbox-bg-hover);
        }
    }

    & > input:checked + .icon {
        fill: white;
        background-color: var(--input-label-color, $input-label-color);

        &:hover {
            background: var(--primary-hover, $primary-hover);
        }
    }

    & > svg + span {
        flex: 1;
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
        color: var(--checkbox-color-disabled, $checkbox-color-disabled);

        input + .icon {
            background: var(--checkbox-bg-disabled, $checkbox-bg-disabled);
        }
    }

    & > input:checked:disabled + .icon {
        fill: white;
        background-color: var(--primary-disabled, $primary-disabled);
    }
}
</style>
