import { useHistoryResource } from '@history';

export default (count = 10) => {
    const transaction = useHistoryResource('Transaction');
    if (!transaction.isLoaded) {
        //transaction.setIncludes(['coin','wallet','operation']);
        transaction.sort('created_at', 'desc');
        transaction.setPerPage(count);

        transaction.load();
    }

    return transaction;
};
