<script>

import { useHistoryComponent } from '@history';
import { useEnvComponent } from '@environment';

export default {
    name: 'Leads',
    extends: useEnvComponent('Layout'),
    components: {
        Environment: useHistoryComponent('Environment'),
    },

    setup() {

        return {
            title: 'history'
        };
    },
};
</script>
