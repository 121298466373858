<script>
import { useProfileComponent } from '@profile';
import { useEnvComponent } from '@environment';

export default {
    name: 'Profile',
    extends: useEnvComponent('Layout'),
    components: {
        Environment: useProfileComponent('Environment'),
    },

    setup() {

        return {
            title: 'profile'
        };
    }
};
</script>
