import { useHistoryResource } from '@history';

export default (filters = {}, sortBy = null) => {
    const transaction = useHistoryResource('Transaction');


    const payload = {
        ...filters,
        include: 'coin,wallet,operation',
    };

    if (sortBy) {
        payload.asc = sortBy;
    }

    transaction.load(payload);

    return transaction;
};
