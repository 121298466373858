<template>
    <div class="dashboard-chart">
        <div class="header">
            <div class="title">{{ $t('dashboard.profit') }}</div>
            <g-fade-container icon="calendar" v-model:is_show="isShowDatepicker" :disabled="true">
                <g-date-picker v-on:change="setDateRange"
                               v-model:from="dateRange.from"
                               v-model:to="dateRange.to"/>
            </g-fade-container>
        </div>
        <g-charts :disabled="true"/>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';

export default {
    name: 'DashboardChart',

    setup() {
        const dateRange = reactive({});
        const isShowDatepicker = ref(false);

        function setDateRange() {
            isShowDatepicker.value = false;
        }

        return {
            dateRange,
            isShowDatepicker,
            setDateRange,
        };
    }

};
</script>

<style lang="scss" scoped>
.dashboard-chart {
    border: 1px solid var(--disabled, $disabled);
    border-radius: var(--rounding-lg, $rounding-lg);
    padding: 24px;

    .title {
        font-weight: 600;
        font-size: var(--text-lg, $text-lg);
        line-height: 150%;
        margin-bottom: 24px;
    }

    &:deep(canvas) {
        height: 351px !important;
        max-height: 351px !important;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -20px;

    &:deep(.g-button) {
        position: relative;
        z-index: 2;
    }
}

@media screen and (max-width: 359px) {
    .dashboard-chart {
        padding: 12px;
    }
}

</style>