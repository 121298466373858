<template>
    <div class="cover">
        <img src="@assets/img/logo-white.svg" alt="LOGO" class="logo"/>
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'cover'
};
</script>

<style lang="scss" scoped>
.cover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: auto;
    color: var(--home-page-color, $home-page-color);
    background-color: var(--home-page-bg, $home-page-bg);
}

.logo {
    top: 50px;
    left: 50px;
    position: absolute;
}

@media (max-width: 764px) {
    .logo {
        top: 25px;
        left: 25px;
        width: 125px;
    }
}
</style>