<template>
    <div class="g-popup" v-on:click="PopUp.close()">
        <div class="popup" v-on:click.stop>
            <g-caption size="5" weight="600" v-html="PopUp.caption"></g-caption>
            <g-link-button class="popup-close" v-on:click="PopUp.close()">
                <g-symbol name="close" height="24" width="24"/>
            </g-link-button>
            <slot/>
        </div>
    </div>
</template>

<script>

import { PopUpManager as PopUp } from '@/Ship';

export default {
    name: 'GPopup',
    components: {},

    setup() {
        return { PopUp };
    }
};
</script>

<style lang="scss" scoped>
.g-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    overflow: auto;
    background-color: var(--popup-overlay, $popup-overlay);
}

.popup {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 40px;
    max-width: calc(100% - 20px);
    width: var(--popup-width, $popup-width);
    background-color: var(--popup-bg, $popup-bg);
    border-radius: var(--rounding-lg, $rounding-lg);
}

.g-caption {
    border-bottom: 1px solid var(--separator-color, $separator-color);
    margin-top: -15px;
    margin-bottom: 35px;
    padding-bottom: 14px !important;
}

.popup-close {
    top: 16px;
    right: 16px;
    position: absolute;
}
</style>
