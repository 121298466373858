import componentLoader from '@/Ship/core/ComponentLoader';
//import actionLoader from "@/Ship/core/ActionLoader";
//import TaskLoader from "@/Ship/core/TaskLoader";
//import resourceLoader from "@/Ship/core/ResourceLoader";

// export function useEnvAction(ask) {
//     const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
//     return actionLoader(requireActions).action(ask);
// }

// export function useEnvActions() {
//     const requireActions = require.context('./actions', true, /[\w-]+\.js$/);
//     return actionLoader(requireActions).list();
// }

// export function useEnvTask(ask) {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).task(ask);
// }

// export function useEnvTasks() {
//     const requireComponents = require.context('./tasks', true, /[\w-]+\.js$/);
//     return TaskLoader(requireComponents).list();
// }

// export function useEnvResource(ask) {
//     const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
//     return resourceLoader(requireResources).resource(ask);
// }

// export function useEnvResources() {
//     const requireResources = require.context('./resources', true, /[\w-]+\.js$/);
//     return resourceLoader(requireResources).list();
// }

export function useEnvComponent(ask) {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).component(ask);
}

export function useEnvComponents() {
    const requireComponents = require.context('./components', true, /[\w-]+\.vue$/);
    return componentLoader(requireComponents).list();
}

export default require('./View').default;
