<template>
    <div class="environment">
        <g-frame>
<!--            <pre style="position: fixed">{{ profile.isValidationPassed()}} &#45;&#45;s {{profile.validate}}</pre>-->
            <div class="form-wrapper">
                <div class="title">{{ $t('profile.account') }}</div>
                <form @submit.prevent @input="logChanges()">
                    <div class="row">
                        <div class="col">
                            <g-input v-model="profile.first_name"
                                     v-bind:label="$t('label.first_name')"
                                     :error="profile.validate.first_name[0]"
                                     :hint="profile.validate.first_name[0]"/>
                        </div>
                        <div class="col">
                            <g-input v-model="profile.last_name"
                                     v-bind:label="$t('label.last_name')"
                                     :error="profile.validate.last_name[0]"
                                     :hint="profile.validate.last_name[0]"/>
                        </div>
                    </div>
                    <div class="row">
                        <g-input v-model="profile.email"
                                 v-bind:label="$t('label.email')"
                                 type="email"
                                 :error="profile.validate.email[0]"
                                 :hint="profile.validate.email[0]"/>
                    </div>
                </form>
                <div class="footer">
                    <g-button size="large" fill="primary"
                              v-on:click="PopUp.open(require('@profile/components/ChangePassword')).setCaption($t('popupTitle.change_password'))">
                        {{ $t('button.change_password') }}
                    </g-button>
                    <g-button size="large" icon-color="danger"
                              v-on:click="PopUp.open(require('@profile/components/RemoveProfile')).setCaption($t('popupTitle.alert'))">
                        {{ $t('button.delete_account') }}
                        <g-symbol name="delete" width="24" height="24"/>
                    </g-button>
                </div>
                <g-flex justify="between" align="center" class="header">
                    <div class="title">{{ $t('profile.api') }}</div>
                    <g-button size="large" fill="primary" v-on:click="addApiClient">
                        <g-symbol name="plus" width="24" height="24"/>
                        {{ $t('button.add_client') }}
                    </g-button>
                </g-flex>
                <div v-for="(item, i) in clientList" v-bind:key="`f-${i}`" class="client-item"  @click='setClientIndex(i)'>
                    <div class="row row-api">
                        <div class="copy-block">
                            <g-input v-model="item.secret"
                                     ref="apiInput"
                                     size="large"
                                     v-bind:label="$t('label.api_key')"
                                     :hint="$t('inputHint.api_key')"
                                     readonly/>
                            <g-tooltip v-bind:tooltip-text="$t('label.copied')"
                                       v-bind:show-tooltip="isShowTooltip.includes(i)"/>
                        </div>
                        <g-button size="large" icon-color="primary" v-on:click="copyInputValue(i)" square>
                            <g-symbol name="copy" width="24" height="24"/>
                        </g-button>
                        <g-button size="large" fill="primary" v-on:click="regenerateApiKey(item)">
                            <g-symbol name="refresh" width="24" height="24"/>
                            {{ $t('button.new-api_key') }}
                        </g-button>
                    </div>
                    <g-input
                        label="Service url"
                        type="url"
                        v-model="item.service_url"
                        @input="logUrlChanges(item, item.service_url)"/>
                    <!--
                    :error="profile.validate.api_clients[i].service_url[0]"
                    :hint="profile.validate.api_clients[i].service_url[0]"
                    -->
                    <div class="footer">
                        <g-button
                            @click="PopUp.open(require('@profile/components/RemoveClient')).setCaption($t('popupTitle.alert')).setPayload({client:item})"
                            size="large"
                            icon-color="danger">
                            {{ $t('button.delete-api_key') }}
                            <g-symbol name="delete" width="24" height="24"/>
                        </g-button>
                    </div>
                </div>
            </div>
        </g-frame>
    </div>
</template>

<script>

import { computed, ref } from 'vue';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useProfileResource, useProfileActions } from '@profile';

const {
    addApiClient,
    regenerateApiKey,
    updateClientUrl
} = useProfileActions();

export default {
    name: 'Environment',

    setup: function() {
        const profile = useProfileResource('Profile');
        const client = useProfileResource('Client');

        let timeout = null;
        const logChanges = () => {

            if (profile.isValidationPassed()) {
                STATE.IS_PROCESSING = true;
                STATE.AUTO_SAVE = false;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    STATE.IS_PROCESSING = false;
                    STATE.AUTO_SAVE = true;
                    profile.save();
                    profile.clearEntity();
                }, 5000);
            }
        };

        const logUrlChanges = (item, payload) => {

            //client.initNestedValidation('service_url', client.state , id);

            //if (client.isValidationPassed()) {
            STATE.IS_PROCESSING = true;
            STATE.AUTO_SAVE = false;
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                STATE.AUTO_SAVE = true;
                STATE.IS_PROCESSING = false;
                updateClientUrl(item.id, payload);
            }, 5000);
            //}
        };

        const setClientIndex = (i) => {
            client.index = i;
        };

        const clientList = computed(() => {
            return client.state.slice().reverse();
        });

        const isShowTooltip = ref([]);
        const copyInputValue = (index) => {
            navigator.clipboard.writeText(clientList.value[index].secret);
            isShowTooltip.value.push(index);

            setTimeout(() => {
                isShowTooltip.value.splice(isShowTooltip.value.indexOf(index), 1);
            }, 3000);
        };

        return {
            PopUp,
            profile,
            client,
            clientList,
            isShowTooltip,
            regenerateApiKey,
            addApiClient,
            copyInputValue,
            logChanges,
            logUrlChanges,
            setClientIndex
        };
    }
};
</script>

<style lang="scss" scoped>
div:deep(.g-frame) {
    padding: 12px 40px 40px;
    display: flex;
}

.copy-block {
    position: relative;
}

.form-wrapper {
    max-width: 744px;
    width: 100%;
    margin: auto;
}

.title {
    font-weight: 400;
    font-size: 20px;
    color: var(--secondary, $secondary);
    margin-bottom: 27px;
}

.row {
    display: flex;
    gap: 14px;
    margin-bottom: 20px;

    .col {
        width: 50%;
    }

    .g-input {
        flex: 1;
    }

    & + .title {
        margin-top: 33px;
    }

    .copy-block {
        flex: 1;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.client-item {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid var(--separator-color, $separator-color);
    border-radius: var(--rounding-lg, $rounding-lg);

    .footer {
        margin-bottom: 0;
    }
}

.header {
    margin-bottom: 20px;

    .title {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1100px) {
    div:deep(.g-frame) {
        padding: 12px 20px 40px;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        gap: 12px;
        justify-content: space-between;

        .g-button {
            width: 50%;
        }
    }

    .row-api {
        flex-wrap: wrap;

        .g-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 550px) {
    .footer {
        flex-direction: column;

        .g-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 460px) {
    .row:not(.row-api) {
        flex-direction: column;
        margin-bottom: 16px;

        .col {
            width: 100%;
        }
    }
}
</style>
