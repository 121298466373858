<template>
    <router-view/>
<!--    <div style="position: absolute; background-color: green; top: 50% ;left: 50%; color: white; font-size: 18px;">{{Preloader.isLoading}}</div>-->
    <g-preloader-overlay v-if="STATE.IS_LOADING"/>
    <g-popup v-if="PopUp.component.value">
        <component v-bind:is="PopUp.component.value" v-bind="PopUp.payload" v-on:performed="PopUp.close()"></component>
    </g-popup>
</template>

<script>

import { PopUpManager as PopUp, STATE } from '@/Ship';

export default {
    name: 'Wallet-APP',

    setup() {

        return { PopUp, STATE };
    },
};
</script>
<style lang="scss"></style>
