<template>
    <button
        v-bind:class="['g-button', {[`${stroke}-stroke`]: stroke}, {[`${fill}-fill`]: fill}, {[`${iconColor}-icon`]: iconColor},  {'square': square}, {'round': round}, size]"
    >
        <slot/>
    </button>
</template>


<script>
export default {
    name: 'GButton',
    props: {
        size: {
            default: 'medium'
        },
        fill: {
            type: String
        },
        iconColor: {
            type: String
        },
        stroke: {
            type: String
        },
        square: {
            type: Boolean,
            default: false
        },
        round: {
            type: Boolean,
            default: false
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    border: none;
}

.g-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--main-text-color, $main-text-color);
    font-weight: 400;
    transition: all 0.2s;
    border: 1px solid var(--button-default-border, $button-default-border);
    background: var(--button-default-bg, $button-default-bg);
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        border-color: var(--button-default-border-hover, $button-default-border-hover);
    }

    &:deep(svg) {
        transition: fill 0.2s;
        flex-shrink: 0;
    }

    &.large {
        padding: 0 20px;
        height: var(--button-height-large, $button-height-large);
        font-size: var(--button-font-size, $button-font-size);
        border-radius: var(--button-rounding, $button-rounding-large);

        &.round,
        &.square {
            width: var(--button-height-large, $button-height-large);
            padding: 5px;
        }
    }

    &.medium {
        padding: 0 18px;
        height: var(--button-height-medium, $button-height-medium);
        font-size: var(--button-font-size, $button-font-size);
        border-radius: var(--button-rounding, $button-rounding-medium);

        &.round,
        &.square {
            padding: 0;
            width: var(--button-height-medium, $button-height-medium);
        }
    }

    &.small {
        padding: 0 10px;
        height: var(--button-height-small, $button-height-small);
        font-size: var(--button-font-size-small, $button-font-size-small);
        border-radius: var(--button-rounding, $button-rounding-small);

        &.round,
        &.square {
            width: var(--button-height-small, $button-height-small);
            padding: 0;
        }
    }

    &.square {
        justify-content: center;
        padding: 0;
    }

    &.round {
        border-radius: 50%;
    }

    &.button-save {
        border: none;
        position: relative;
        background: var(--button-notification-bg, $button-notification-bg);
        color: var(--button-notification-color, $button-notification-color);

        &:deep(svg) {
            fill: var(--button-notification-color, $button-notification-color);
        }
    }

    &.danger-icon {
        &:deep(svg) {
            fill: var(--danger, $danger);
        }
    }

    &.primary-icon {
        &:deep(svg) {
            fill: var(--primary, $primary);
        }
    }

    &.success-icon {
        &:deep(svg) {
            fill: var(--success, $success);
        }
    }

    &.warning-icon {
        &:deep(svg) {
            fill: var(--warning, $warning);
        }
    }

    &:disabled {
        pointer-events: none;
        cursor: default;
        color: var(--disabled, $disabled);
        background-color: var(--button-disabled-bg, $button-disabled-bg);
        border: 1px solid var(--disabled, $disabled);

        &:deep(svg) {
            fill: var(--disabled, $disabled);
        }
    }

    &.secondary-stroke {
        border: 1px solid var(--secondary, $secondary);
        font-weight: 400;

        &:hover {
            color: var(--secondary, $secondary-hover);
        }

        &:deep(svg) {
            fill: var(--secondary-hover, $secondary-hover);
        }

        &:disabled {
            color: var(--disabled, $disabled);
            background-color: var(--button-disabled-bg, $button-disabled-bg);
            border: 1px solid var(--disabled, $disabled);

            &:deep(svg) {
                fill: var(--disabled, $disabled);
            }
        }
    }

    &.primary-fill,
    &.danger-fill,
    &.success-fill,
    &.warning-fill {
        font-weight: 600;
        border: none;
        color: var(--button-primary-color, $button-primary-color);

        &:deep(svg) {
            fill: var(--button-primary-color, $button-primary-color);
        }

        &:disabled {
            color: var(--button-primary-color, $button-primary-color);
            background-color: var(--primary-disabled, $primary-disabled);

            &:deep(svg) {
                fill: var(--button-primary-color, $button-primary-color);
            }
        }
    }

    &.primary-fill {
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.danger-fill {
        background-color: var(--danger, $danger);

        &:hover {
            background-color: var(--danger-hover, $danger-hover);
        }
    }

    &.success-fill {
        background-color: var(--success, $success);

        &:hover {
            background-color: var(--success-hover, $success-hover);
        }
    }

    &.warning-fill {
        background-color: var(--warning, $warning);

        &:hover {
            background-color: var(--warning-hover, $warning-hover);
        }
    }
}
</style>